import { cloneElement, FC, useEffect, useRef, useState } from 'react';

import {
  AnimationOptionsWithOverrides,
  MotionKeyframesDefinition,
} from '@motionone/dom';

import { useRandomId } from '@utils/string';

import clsx from 'clsx';
import { animate, AnimationControls } from 'motion';

type AnimationSettings = [
  MotionKeyframesDefinition,
  AnimationOptionsWithOverrides?,
];
type Props = {
  children: JSX.Element;
  show?: boolean | null;
  enter: AnimationSettings;
  exit: AnimationSettings;
};

export const Presence: FC<Props> = ({ children, show, enter, exit }) => {
  const uniqueClass = `s` + useRandomId();

  const [inDom, setInDom] = useState(show);
  const animationRef = useRef<AnimationControls | undefined>();
  const showRef = useRef(show);

  // Adding to DOM and syncing prop with ref
  useEffect(() => {
    showRef.current = show;
    if (show && !inDom) setInDom(true);
  }, [inDom, show]);

  useEffect(() => {
    if (!inDom) return;

    // stopping previous animation
    animationRef.current?.stop();

    const newAnimation = animate(`.${uniqueClass}`, ...(show ? enter : exit));

    animationRef.current = newAnimation;
    animationRef.current.finished.then(() => {
      // do not hide from DOM — animation has been changed
      if (animationRef.current !== newAnimation) return;
      // do not hide from DOM — current show is true
      if (showRef.current) return;

      animationRef.current = undefined;
      setInDom(false);
    });
  }, [enter, inDom, exit, show, uniqueClass]);

  return inDom
    ? cloneElement(children, {
        ...children.props,
        className: clsx(uniqueClass, children.props?.className),
      })
    : null;
};
