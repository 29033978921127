import { EventApi, MapApi, UserApi } from '../types';
import { generateReqResLoopHelper } from '../utils/ts';

export type WithType<T> = T & { type: string };

export type InitReq = {
  user_id: string;
};
export type InitRes = {
  user: UserApi;
  event: EventApi;
};
export const init = generateReqResLoopHelper<InitReq, InitRes>('init');

export type GetMapReq = {
  map_id: string;
};
export type GetMapRes = {
  map: MapApi;
};
export const getMap = generateReqResLoopHelper<GetMapReq, GetMapRes>('getMap');
