import { FC, forwardRef, PropsWithChildren } from 'react';

import { RecipeVariants } from '@vanilla-extract/recipes';

import { button } from './Button.css';

import clsx from 'clsx';

type Props<T extends keyof JSX.IntrinsicElements> = PropsWithChildren<
  RecipeVariants<typeof button> & JSX.IntrinsicElements[T]
>;

export const Button = forwardRef<HTMLButtonElement, Props<'button'>>(
  ({ className, design = 'filled', size = 'md', ...props }, ref) => {
    return (
      <button
        {...props}
        ref={ref}
        className={clsx(className, button({ design, size }))}
      />
    );
  },
);

export const ButtonLink: FC<Props<'a'> & { href: string }> = ({
  className,
  href,
  design = 'filled',
  size = 'md',
  children,
  ...props
}) => {
  return (
    <a
      href={href}
      {...props}
      className={clsx(className, button({ design, size }))}
    >
      {children}
    </a>
  );
};
