import { UserApi } from '../types';

export type PlayerPositions = {
  [userIdOnMap: string]: ITwoPointCoords | null;
};
export const playerPositionsSyncMapName = 'playersPositions';

// `null` | `undefined` — no calls
// `number` — call is active, we tell the client about the distance to this user
// `'staged'` — stage the connection, because we're close to each other
export type PlayerCalls = {
  [userIdInCall: string]: 'staged' | number | null | undefined;
};
export const playerCallsSyncMapName = 'playerCalls';

export type BroadcastCalls = {
  [userIdInCall: string]: null | true;
};
export const broadcastCallsSyncMapName = 'broadcastCall';

export type PlayerData = Omit<UserApi, 'id'>;
export const playerDataSyncMapName = 'players';
