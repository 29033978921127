import { SVGProps } from 'react';

export const Lobby = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0569 13.6597C21.0569 13.7661 21.0569 13.8387 21.0569 13.9064C21.0569 16.8091 21.0569 19.7117 21.0569 22.6144C21.0569 23.0933 20.8615 23.3981 20.4659 23.5239C20.3611 23.5578 20.2467 23.5674 20.1419 23.5674C14.504 23.5674 8.86129 23.5674 3.22338 23.5771C2.65625 23.5771 2.28452 23.1224 2.28929 22.6483C2.29406 19.7214 2.28929 16.7946 2.29406 13.8677C2.29406 13.8048 2.29882 13.742 2.30359 13.6597C8.54675 13.6597 14.7851 13.6597 21.0569 13.6597Z"
      fill="currentColor"
    />
    <path
      d="M9.0761 8.36707C9.43353 7.81557 9.76237 7.3076 10.0912 6.79964C10.2151 6.61097 10.339 6.42713 10.4534 6.23362C10.5773 6.0256 10.7584 5.96271 10.9824 6.02076C11.397 6.13203 11.8069 6.2433 12.2167 6.36908C12.4884 6.45132 12.6028 6.63516 12.6028 6.98831C12.6028 8.0381 12.6028 9.08306 12.6028 10.1329C12.6028 10.2199 12.6028 10.307 12.6028 10.4134C12.6886 10.4231 12.7553 10.4328 12.822 10.4328C13.6798 10.4328 14.5377 10.4328 15.3955 10.4376C15.5242 10.4376 15.61 10.4328 15.6576 10.278C15.6767 10.2103 15.8197 10.1474 15.9054 10.1474C16.7728 10.1377 17.6354 10.1329 18.5028 10.1522C18.6219 10.157 18.7411 10.2877 18.8602 10.3554C18.8507 10.3844 18.8364 10.4134 18.8269 10.4376C19.3177 10.4376 19.8086 10.4376 20.2995 10.4376C20.8142 10.4376 21.3337 10.4376 21.8484 10.4376C22.1534 10.4376 22.2249 10.5199 22.2249 10.8246C22.2249 10.9843 22.2249 11.1439 22.2249 11.3423C22.3821 11.3423 22.5108 11.3375 22.6442 11.3423C22.9683 11.352 23.1923 11.589 23.1923 11.9131C23.1923 12.2373 22.9683 12.4792 22.6395 12.484C21.8531 12.4888 21.0668 12.484 20.2804 12.484C13.7751 12.484 7.26987 12.484 0.764594 12.484C0.335675 12.484 0.0973864 12.2518 0.116449 11.8648C0.130747 11.5697 0.340441 11.352 0.631153 11.3375C0.764594 11.3326 0.898036 11.3375 1.07914 11.3375C1.07914 11.1585 1.07914 10.994 1.07914 10.8295C1.07914 10.5102 1.15062 10.4328 1.47469 10.4328C2.38019 10.4328 3.28569 10.4328 4.19118 10.4328C4.55815 10.4328 4.92035 10.4328 5.28731 10.4328C5.60185 10.4328 5.60185 10.4328 5.60185 10.1232C5.60185 9.08306 5.60185 8.04294 5.60185 7.00282C5.60185 6.54808 5.6924 6.43197 6.12609 6.31103C6.44063 6.22395 6.7504 6.13687 7.06494 6.04979C7.43191 5.94819 7.52246 5.98206 7.73215 6.31103C8.1849 6.98347 8.61858 7.65592 9.0761 8.36707Z"
      fill="currentColor"
    />
    <path
      d="M11.44 2.92443C11.4161 4.279 10.3581 5.30944 8.99513 5.2659C7.65594 5.22237 6.71232 4.05647 6.75521 2.81316C6.7981 1.51181 7.86087 0.471689 9.16193 0.500715C10.4392 0.529742 11.459 1.62307 11.44 2.92443Z"
      fill="currentColor"
    />
    <path
      d="M15.9292 9.86156C15.8244 9.2133 16.3152 8.59891 16.8584 8.43926C16.9585 8.41023 17.049 8.36186 17.1491 8.31832C17.0919 8.24575 17.03 8.17802 16.9537 8.0861C17.1491 8.0861 17.3302 8.0861 17.5446 8.0861C17.4302 8.18286 17.3587 8.24575 17.2825 8.31348C17.3778 8.35702 17.4683 8.4054 17.5684 8.43442C18.1879 8.61342 18.6167 9.22782 18.5405 9.86156C17.6732 9.86156 16.806 9.86156 15.9292 9.86156Z"
      fill="currentColor"
    />
  </svg>
);
