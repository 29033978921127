import { persistentMap } from '@nanostores/persistent';

import {
  setLocalAudio,
  setLocalAudioDevice,
  setLocalVideo,
  setLocalVideoDevice,
} from './dailyCalls';

import { onSet } from 'nanostores';

type BoolSetting = '' | 'on';
type OptionalStringSetting = '' | string;
export const toggleSetting = (val?: BoolSetting) => (val ? '' : 'on');
export const $settings = persistentMap<{
  muteAll?: BoolSetting;
  enableCamera?: BoolSetting;
  enableMic?: BoolSetting;

  videoInput?: OptionalStringSetting;
  audioInput?: OptionalStringSetting;
  audioOutput?: OptionalStringSetting;
}>('settings:', {
  enableCamera: 'on',
  enableMic: 'on',
});
onSet(
  $settings,
  ({
    changed,
    newValue: { enableCamera, enableMic, videoInput, audioInput } = {},
  }) => {
    switch (changed) {
      case 'enableMic':
        setLocalAudio(!!enableMic);
        break;
      case 'enableCamera':
        setLocalAudio(!!enableCamera);
        break;

      case 'videoInput':
        if (videoInput) setLocalVideoDevice(videoInput);
        break;

      case 'audioInput':
        if (audioInput) setLocalAudioDevice(audioInput);
        break;

      default:
        break;
    }
  },
);

const showHandler = () => {
    const { enableCamera, enableMic } = $settings.get() || {};
    setLocalAudio(!!enableMic);
    setLocalVideo(!!enableCamera);
  },
  hideHandler = () => {
    setLocalAudio(false);
    setLocalVideo(false);
  };
addEventListener('visibilitychange', () => {
  /**
   * For now we found that this is not useful and/or expected.
   * Users need more feedback about their video/audio being disabled.
   */
  return false;

  if (document.hidden) hideHandler();
  else showHandler();
});
