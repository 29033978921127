import { $gameActive } from '@state/map';

import { atom } from 'nanostores';

export const $parentFrameOpened = atom(false);

export const initParentListener = () => {
  window.addEventListener(
    'message',
    (e: MessageEvent<{ command: 'closeStage' | 'closeLeaderboard' }>) => {
      switch (e.data.command) {
        case 'closeStage':
          $gameActive.set(true);
          $parentFrameOpened.set(false);
          break;

        case 'closeLeaderboard':
          $parentFrameOpened.set(false);
          break;
      }
    },
  );
};
initParentListener();

const sendMsg = (msg: unknown) => {
  const target = window.parent as Window;
  if (target?.postMessage) {
    target.postMessage(msg, '*');
    $parentFrameOpened.set(true);
  }
};

export const openStage = (stageChannelId: string) => {
  $gameActive.set(false);
  sendMsg({ command: 'openStage', stageChannelId });
};
export const openLeaderboard = () => {
  sendMsg({ command: 'openLeaderboard' });
};
