import { changeSyncMapById } from '@logux/client';

import { $logux } from '@services/logux';
import { $PlayerPositions, $Players } from '@services/player';
import { $currentMap } from '@state/map';
import { $canPlayerMove } from '@state/misc';
import { $currentUser } from '@state/user';
import { throttle } from 'shared/utils/function';

import { $positioning, Objects } from './objects';

import { js as EasyStar } from 'easystarjs';
import { Pos } from 'game/utils/pos';
import Phaser from 'phaser';

type UserId = string;

export class Players {
  private client: IStoreValue<typeof $logux>;
  private currentUser: IStoreValue<typeof $currentUser>;
  private currentMap: IStoreValue<typeof $currentMap>;
  private canPlayerMove: IStoreValue<typeof $canPlayerMove>;

  private unsubs: Record<
    '_logux' | '_mapPositions' | '_canPlayerMove' | UserId,
    () => void
  > = {};
  private avatars: Record<UserId, Phaser.Physics.Arcade.Image | Pos | null> =
    {};

  private getPos: (realPos: ITwoPointCoords) => Pos;

  private multiplicator: number;

  constructor(
    private scene: Phaser.Scene,
    private colliding: Phaser.GameObjects.GameObject[],
    private objects: Objects,
    private tileWidth: number,
    private tileHeight: number,
  ) {
    this.client = $logux.get();
    this.currentUser = $currentUser.get();
    this.currentMap = $currentMap.get();
    this.canPlayerMove = $canPlayerMove.get();

    this.getPos = (pos) => new Pos(pos, this.tileWidth, this.tileHeight);

    /**
     * We use it to adjust speed and zoom to match the experience.
     * We make all the numbers below adjusted for 16*16 tilesets, the rest
     * are adjusted accordingly.
     */
    this.multiplicator = this.tileWidth / 16;

    this.unsubs._canPlayerMove = $canPlayerMove.listen(
      (v) => (this.canPlayerMove = v),
    );
    // It will change if we lose network connection for some reason
    this.unsubs._logux = $logux.listen((v) => (this.client = v));
  }

  private initialLoad = true;
  initPlayers() {
    this.unsubs._mapPositions = $PlayerPositions(
      this.currentMap.id,
      this.client,
    ).listen((playerPositions, changedId) => {
      if (playerPositions.isLoading) return;

      if (typeof changedId === 'number') {
        console.error('changedId is a number', changedId, playerPositions);
        return;
      }

      // Initially we need to add everybody
      if (this.initialLoad) {
        for (const [id, pos] of Object.entries(playerPositions)) {
          if (id === 'id' || id === 'isLoading') continue;
          // typeguarding against something that can hardly happen
          if (!Array.isArray(pos)) continue;
          // We've already initialized current user
          if (id === this.currentUser.id) continue;

          // TODO: Seems we can omit displaying those users at all until they are close
          // It can also work like fog of war
          this.addPlayer(id, this.getPos(pos));
        }
        this.initialLoad = false;
        return;
      }

      // After initial load we can only react to changed key
      const pos = playerPositions[changedId];

      // Adding new player
      if (!this.avatars[changedId] && Array.isArray(pos)) {
        return this.addPlayer(changedId as string, this.getPos(pos));
      }

      // Removing player from the map
      if (!pos) {
        return this.removePlayer(changedId);
      }

      // Moving player to a new position.
      // We never move current player, as we prefer local position to remote.
      if (changedId !== this.currentUser.id)
        this.movePlayer(changedId, this.getPos(pos));
    });

    // Adding current user initially
    const spawnPoint = $positioning.get().spawnPoint;
    if (!spawnPoint) throw new Error('Spawn point is not set');

    this.addPlayer(this.currentUser.id, spawnPoint);
    this.updatePos();
  }

  private autoPathUnsub: (() => void) | null = null;
  handleClickNav(finder: EasyStar, x: number, y: number) {
    if (!this.canPlayerMove) return;

    this.autoPathUnsub?.();
    const avatar = this.avatars[this.currentUser.id];
    if (!avatar || avatar instanceof Pos) return;

    const currPos = this.getPos([0, 0]);
    currPos.coords = [avatar.x, avatar.y];

    let cancelTweens: (() => void) | null = null;
    try {
      const i = finder.findPath(...currPos.realPos, x, y, (paths) => {
        if (!paths) return;

        /**
         * First path is always current realPos. We want to ignore it, because
         * otherwise the animation may look clunky if we sit in-between two tiles.
         */
        const tweens = paths.slice(1).map(({ x, y }) => {
          const pos = this.getPos([x, y]);

          return {
            targets: avatar,
            x: {
              value: pos.coords[0],
              duration: 120,
            },
            y: {
              value: pos.coords[1],
              duration: 120,
            },
          };
        });
        const timeline = this.scene.tweens.timeline({ tweens });
        timeline.once(Phaser.Tweens.Events.TIMELINE_COMPLETE, () => {
          this.updatePos();
          this.autoPathUnsub = null;
        });
        let count = 0;
        timeline.once(Phaser.Tweens.Events.TIMELINE_LOOP, () => {
          count++;
          if (count % 3 === 0) this.updatePos();
        });
        cancelTweens = () => {
          timeline.stop();
        };
      });
      finder.calculate();

      this.autoPathUnsub = () => {
        finder.cancelPath(i);
        cancelTweens?.();
        this.autoPathUnsub = null;
      };
    } catch (error) {
      console.log('impossible to find path there');
    }
  }

  private addPlayer(id: string, pos: Pos) {
    const scene = this.scene;
    this.avatars[id] = pos;

    const textureKey = `player:${id}`;

    const addInScene = () => {
      console.log('adding player in scene', id);

      const innerPos = this.avatars[id];
      // Avatar is already initialized
      if (!(innerPos instanceof Pos)) return;

      console.log(`initializing avatar on coords ${innerPos.realPos}`);
      const [x, y] = innerPos.coords;

      const img = scene.physics.add.image(x, y, textureKey);

      img.setPushable(false);
      scene.physics.add.collider(img, this.colliding);
      scene.physics.add.collider(
        img,
        Object.values(this.avatars).filter(
          Boolean,
        ) as Phaser.Physics.Arcade.Image[],
      );

      if (id === this.currentUser.id) {
        // Following current user
        scene.cameras.main.startFollow(img, true, 0.05, 0.05);
        scene.cameras.main.setZoom(
          2 / this.multiplicator,
          2 / this.multiplicator,
        );

        // We only need to track portal collision for current player
        this.setObjectsCollision(img);

        this.prevPos = innerPos;
      }
      img.setDisplaySize(this.tileWidth, this.tileHeight);

      const clone = this.avatars[id];
      if (clone && !(clone instanceof Pos)) {
        clone.destroy();
      }
      this.avatars[id] = img;
    };

    if (this.scene.textures.exists(textureKey)) {
      addInScene();
    } else {
      this.unsubs[id] = $Players(id, this.client).listen((v) => {
        if (v.isLoading) return;

        console.log('added subscription added', v.id);

        const loader = scene.load.image(textureKey, v.avatar_url);
        loader.once(`filecomplete-image-${textureKey}`, () => {
          addInScene();
          // We don't need to create any new instances even if something changes
          this.unsubs[id]?.();
        });
        loader.start();
      });
    }
  }

  private movePlayer(id: string, pos: Pos) {
    const avatar = this.avatars[id];
    if (avatar && !(avatar instanceof Pos)) {
      const [x, y] = pos.coords;
      this.scene.tweens.add({
        targets: avatar,
        x: { value: x, duration: 200 },
        y: { value: y, duration: 200 },
      });
    }
  }

  private removePlayer(id: string) {
    const avatar = this.avatars[id];
    if (!(avatar instanceof Pos)) {
      this.scene.tweens
        .add({
          targets: avatar,
          scaleX: { value: 0, duration: 200 },
          scaleY: { value: 0, duration: 200 },
        })
        .once(Phaser.Tweens.Events.TWEEN_COMPLETE, () => {
          avatar?.destroy();
          this.avatars[id] = null;
        });
    }
  }

  private prevPos!: Pos;
  private updatePos = throttle(() => {
    const avatar = this.avatars[this.currentUser.id];
    if (!avatar || avatar instanceof Pos) return;

    const { x, y } = avatar;
    const coordsChanged = this.prevPos.snapAndAssignRealPosFromCoords([x, y]);
    if (coordsChanged) {
      changeSyncMapById(this.client, $PlayerPositions, this.currentMap.id, {
        [this.currentUser.id]: this.prevPos.realPos,
      });
    }
  }, 200);

  update(cursors: Phaser.Types.Input.Keyboard.CursorKeys) {
    const avatar = this.avatars[this.currentUser.id];

    if (!cursors || !avatar || avatar instanceof Pos) return;

    const speed = 120 * this.multiplicator,
      setVelocityX = avatar.setVelocityX.bind(avatar),
      setVelocityY = avatar.setVelocityY.bind(avatar);

    if (!this.canPlayerMove) {
      setVelocityX(0);
      setVelocityY(0);
      return;
    }

    const manualHandling =
      cursors.left?.isDown ||
      cursors.right?.isDown ||
      cursors.up?.isDown ||
      cursors.down?.isDown;
    if (manualHandling) this.autoPathUnsub?.();

    if (cursors.left?.isDown) setVelocityX(-speed);
    else if (cursors.right?.isDown) setVelocityX(speed);
    else setVelocityX(0);

    if (cursors.up?.isDown) setVelocityY(-speed);
    else if (cursors.down?.isDown) setVelocityY(speed);
    else setVelocityY(0);

    this.updatePos();
  }

  setObjectsCollision(
    avatar: Phaser.Types.Physics.Arcade.ImageWithDynamicBody,
  ) {
    this.scene.physics.add.collider(
      avatar,
      this.objects.group,
      (_: unknown, zone: Phaser.Types.Physics.Arcade.GameObjectWithBody) => {
        // We do not trigger collision control when user is moving automatically
        if (!this.autoPathUnsub) this.objects.handleCollision(_, zone);
      },
    );
  }

  destroy() {
    console.log('[players] destroy');
    Object.values(this.unsubs).forEach((fn) => fn());
    this.prevPos.coords = [-10000, -10000];
  }
}
