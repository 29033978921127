import { persistentAtom } from '@nanostores/persistent';

import { trackEvent } from '@logic/analytics';
import { createPersistedCommaSplitAtom } from '@utils/state/idsPersisted';

import { atom, onSet, onStart } from 'nanostores';

export const trackOnboardingState = (State: string) =>
  trackEvent('Onboarding', { State });

export const $permissionCheck = atom<'initial' | 'needAsk' | 'ok' | 'err'>(
  'initial',
);
onStart($permissionCheck, async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const doWeHavePermission = devices.some((device) => !!device.label);
    if (!doWeHavePermission) trackOnboardingState('Ask permissions');

    $permissionCheck.set(doWeHavePermission ? 'ok' : 'needAsk');
  } catch (error) {
    trackOnboardingState('Error');
    $permissionCheck.set('err');
  }
});

export const $onboardingFinished = persistentAtom<'no' | 'yep'>(
  'onboarding:finished',
  'no',
);
onSet($onboardingFinished, ({ newValue }) => {
  newValue === 'yep' && trackOnboardingState('Finish');
});

const mapOnbFinishedStores = createPersistedCommaSplitAtom(
  'mapOnboarding:finished',
);
export const $mapOnboardingFinishedMapIds = mapOnbFinishedStores.$store;
export const addFinishedMapId = mapOnbFinishedStores.add;
