import { $parentFrameOpened } from '@logic/postMessage';
import { createPersistedCommaSplitAtom } from '@utils/state/idsPersisted';
import { EventApi } from 'shared/types';

import { atom, computed } from 'nanostores';

export const $debugMode = atom(false);

export const $iframeData = atom<
  | {
      target: string;
      iframeProps: Record<string, string>;
    }
  | undefined
>();
export const $typeformData = atom<{ id: string } | undefined>();
const finishedForms = createPersistedCommaSplitAtom('typeform:finishedFormIds');
export const $finishedForms = finishedForms.$store;
export const addFinishedFormId = finishedForms.add;

export const $showHelp = atom(false);
export const $showSettings = atom(false);

export const $modalOpened = atom(false);
export const $fullscreenOpened = atom(false);

export const $canPlayerMove = computed(
  [$modalOpened, $fullscreenOpened, $parentFrameOpened],
  (modal, fullscreen, frameOpened) => !modal && !fullscreen && !frameOpened,
);

export const $event = atom<EventApi>();

export const $mapDailyTokens = computed($event, (event) =>
  Object.fromEntries(event.map_tokens),
);
