import { syncMapTemplate } from '@logux/client';

import {
  BroadcastCalls,
  broadcastCallsSyncMapName,
  PlayerCalls,
  playerCallsSyncMapName,
  PlayerData,
  playerDataSyncMapName,
  PlayerPositions,
  playerPositionsSyncMapName,
} from 'shared/server/playerTypes';

export const $PlayerPositions = syncMapTemplate<PlayerPositions>(
  playerPositionsSyncMapName,
);

export const $PlayerCalls = syncMapTemplate<PlayerCalls>(
  playerCallsSyncMapName,
);

export const $BroadcastCalls = syncMapTemplate<BroadcastCalls>(
  broadcastCallsSyncMapName,
);

export const $Players = syncMapTemplate<PlayerData>(playerDataSyncMapName);
