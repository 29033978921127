import { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { Notifier } from '@airbrake/browser';

type BoundaryProps = PropsWithChildren<{
  fallback: React.ReactElement;
}>;

const notifier = new Notifier({
  projectId: 418479,
  projectKey: 'c8ee9eb2b009275051a13303a0f6a731',
});
notifier.addFilter(function (notice) {
  /**
   * These exceptions are not expected to be caught. WS throws errors when the
   * connection is cut off, which is fine, because Logux will reestablish the
   * connection automagically. I really don't know how these errors end up here,
   * as WS errors are not thrown. I guess, this is a quirk of the airbrake js lib.
   * Setting to ignore this error.
   */
  if (notice.errors?.[0]?.type === 'WS Error') {
    return null;
  }
  return notice;
});

const report = (err: Error, info: unknown) =>
  !import.meta.env.DEV && notifier.notify({ err, params: { info } });

export const Boundary: FC<BoundaryProps> = ({ children, fallback }) => {
  return (
    <ErrorBoundary onError={report} fallback={fallback}>
      {children}
    </ErrorBoundary>
  );
};
