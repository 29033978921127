import { FC, SVGProps } from 'react';

import * as icons from './assets';

export type IconNames = keyof typeof icons;

export const Icon: FC<
  { name: IconNames; label: string } & SVGProps<SVGSVGElement>
> = ({ name, label, ...props }) => {
  const Cmp = icons[name];

  return (
    <Cmp
      focusable={false}
      width={18}
      height={18}
      aria-label={label}
      {...props}
    />
  );
};
