import { useEffect, useRef } from 'react';

import { ReadableAtom } from 'nanostores';

/**
 * Returns a ref with an updated value of a store.
 */
export const useTransientUpdate = <T>(store: ReadableAtom<T>) => {
  const ref = useRef<T>(store.get());
  useEffect(() => store.listen((val) => (ref.current = val)), [store]);

  return ref;
};

export const useTransientUpdateWithGetter = <T, Y>(
  store: ReadableAtom<T>,
  getter: (value: T) => Y,
) => {
  const ref = useRef<Y>(getter(store.get()));
  useEffect(
    () => store.listen((val) => (ref.current = getter(val))),
    [getter, store],
  );

  return ref;
};
