export type CoordObject = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type Entries<T> = {
  [K in keyof T]: { name: K; value: T[K] };
}[keyof T][];
type TypedProperties<T> = { properties: Entries<T> };

export type PositioningObj = CoordObject &
  TypedProperties<{ target: string; toX?: number; toY?: number }>;

export type IFrameActionObj = CoordObject & {
  name: 'iframe';
} & TypedProperties<{
    url: string;
  }>;
export type TableActionObj = CoordObject & {
  name: 'iframe';
} & TypedProperties<{
    id: string;
  }>;
export type TypeformActionObj = CoordObject & {
  name: 'typeform';
} & TypedProperties<{
    id: string;
  }>;

interface ColliderZone {
  handleCollision(): Promise<void> | void;
  handleCollisionEnd?: () => Promise<void> | void;
}
export abstract class ZoneClass
  extends Phaser.GameObjects.Zone
  implements ColliderZone
{
  constructor(
    public scene: Phaser.Scene,
    x: number,
    y: number,
    width?: number,
    height?: number,
  ) {
    super(scene, x, y, width, height);
  }

  protected props!: unknown[];
  setProperties(props: unknown[]) {
    this.props = props;
  }
  init() {
    // Optional
  }
  handleCollision() {
    throw new Error('implement me');
  }
  handleCollisionEnd() {
    return;
  }
}
export interface ZoneClassCreator {
  new (
    scene: Phaser.Scene,
    x: number,
    y: number,
    width?: number,
    height?: number,
  ): ZoneClass;
}
