import { randBetween } from 'shared/utils/number';
import { truthyTypeGuard } from 'shared/utils/ts';

import { atom, onMount } from 'nanostores';

const getParams = () => {
  const parsedParams = new URLSearchParams(window.location.search);
  let user_id = parsedParams.get('user_id'),
    auth_token = parsedParams.get('auth_token'),
    map_id = parsedParams.get('map_id');

  if (import.meta.env.VITE_STAGE || import.meta.env.DEV) {
    if (!(user_id && auth_token && map_id)) {
      map_id = 'map1';
      user_id = 'uid' + randBetween(1, 10000000, true);
      auth_token = 'tok' + randBetween(1, 10000000, true);

      const newParams = new URLSearchParams({
        user_id,
        auth_token,
        map_id,
      });
      window.location.href = `/?${newParams.toString()}`;
    }
  }
  try {
    truthyTypeGuard(user_id);
    truthyTypeGuard(map_id);
    truthyTypeGuard(auth_token);
  } catch (error) {
    throw new Error('user_id and auth_token GET parameters are expected');
  }

  return { user_id, auth_token, map_id };
};

export const $urlParams = atom<{
  user_id: string;
  map_id: string;
  auth_token: string;
}>();

export const updateUrlParams = () => $urlParams.set(getParams());
const locationChangeEvents = ['popstate', 'replacestate'] as const;
onMount($urlParams, () => {
  updateUrlParams();
  locationChangeEvents.forEach((name) =>
    addEventListener(name, updateUrlParams),
  );

  return () => {
    locationChangeEvents.forEach((name) =>
      removeEventListener(name, updateUrlParams),
    );
  };
});

export const changeMapId = (newId: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set('map_id', newId);
  history.pushState(void 0, '', url);
  updateUrlParams();
};
