import { FC } from 'react';

import { DailyParticipant } from '@daily-co/daily-js';
import { useSync } from '@logux/client/react';
import { useStore } from '@nanostores/react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { $BroadcastCalls, $Players } from '@services/player';
import {
  $activeSpeaker,
  $callParticipantsComputed,
  $localVideo,
} from '@state/dailyCalls';
import { $currentMap } from '@state/map';
import { $settings } from '@state/settings';
import { $currentUser } from '@state/user';
import { CALL_RADIUS, UNSUB_RADIUS } from 'shared/server/constants';
import { BroadcastCalls } from 'shared/server/playerTypes';

import { CallTile } from './CallTile';

import { otherVideoWrapper, videoOpacity } from './Call.css';

export const SelfVideo: FC = () => {
  const local = useStore($localVideo),
    user = useStore($currentUser),
    { id: mapId } = useStore($currentMap);

  const activeSpeaker = useStore($activeSpeaker);
  const broadcaster = useSync($BroadcastCalls, mapId),
    isBroadcasting = (broadcaster as BroadcastCalls)[user.id];

  const { enableCamera } = useStore($settings);

  return (
    <CallTile
      local
      broadcast={!!isBroadcasting}
      active={activeSpeaker === local?.session_id}
      video={enableCamera ? local?.videoTrack : undefined}
      audio={local?.audioTrack}
      screenshare={local?.screenVideoTrack}
      name={user.name}
      avatarUrl={user.avatar_url}
    />
  );
};

export const OtherVideos: FC = () => {
  const { muteAll } = useStore($settings);
  const participantsStore = useStore($callParticipantsComputed),
    participants = useStore(participantsStore);

  const participantsArray = Object.entries(participants).sort(
    ([, a], [, b]) => +(b?.broadcast ?? 0) - +(a?.broadcast ?? 0),
  );

  return (
    <>
      {participantsArray.map(([userId, tileData]) => {
        if (!tileData.participant) return null;
        return (
          <div
            className={otherVideoWrapper}
            style={assignInlineVars({
              [videoOpacity]: getOpacity(tileData.distance) + '',
            })}
            key={userId}
          >
            <OtherParticipantTile
              broadcast={tileData.broadcast}
              participant={tileData.participant}
              muteAll={!!muteAll}
            />
          </div>
        );
      })}
    </>
  );
};

const OtherParticipantTile: FC<{
  muteAll: boolean;
  participant: DailyParticipant;
  broadcast?: boolean;
}> = ({ muteAll, participant, broadcast }) => {
  const player = useSync($Players, participant.user_name);
  const activeSpeaker = useStore($activeSpeaker);

  if (player.isLoading) return null;

  return (
    <CallTile
      broadcast={broadcast}
      active={activeSpeaker === participant.session_id}
      video={participant?.videoTrack}
      screenshare={participant?.screenVideoTrack}
      audio={participant?.audioTrack}
      name={player.name}
      mute={!!muteAll}
      avatarUrl={player.avatar_url}
    />
  );
};

const dif = UNSUB_RADIUS - CALL_RADIUS;
const getOpacity = (distance: number) => {
  if (distance <= CALL_RADIUS) return 1;
  else if (distance >= UNSUB_RADIUS) return 0;
  else return 1 - (distance - CALL_RADIUS) / dif;
};
