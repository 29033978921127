import DailyIframe, {
  DailyEventObjectActiveSpeakerChange,
  DailyEventObjectParticipant,
  DailyParticipant,
  DailyParticipantsObject,
} from '@daily-co/daily-js';

import { $videoStream } from '@logic/videoStream';
import { $logux } from '@services/logux';
import { $BroadcastCalls, $PlayerCalls } from '@services/player';

import { $currentMap, $gameActive } from './map';
import { $mapDailyTokens } from './misc';
import { $settings } from './settings';
import { $currentUser } from './user';

import { atom, computed, map, onSet } from 'nanostores';

const callObject = DailyIframe.createCallObject({
  dailyConfig: {
    camSimulcastEncodings: [
      {
        maxBitrate: 150000,
        maxFramerate: 25,
      },
    ],
  },
});
callObject.setBandwidth({
  kbs: 150,
  trackConstraints: { frameRate: 25, width: 200, height: 150 },
});

const $dailyCallParticipants = map<Partial<DailyParticipantsObject>>({});
const singleParticipantEventNames = [
  'participant-joined',
  'participant-updated',
  'participant-left',
] as const;
const participantEventHandler = (event?: DailyEventObjectParticipant) => {
  if (!event) return;

  const { action, participant } = event;
  switch (action) {
    case singleParticipantEventNames[0]:
    case singleParticipantEventNames[1]: {
      $dailyCallParticipants.setKey(
        participant.local ? 'local' : participant.user_name,
        participant,
      );
      break;
    }

    case singleParticipantEventNames[2]: {
      const state = $dailyCallParticipants.get();
      delete state[participant.user_id];
      $dailyCallParticipants.notify();
      break;
    }
  }
};
singleParticipantEventNames.forEach((name) =>
  callObject.on(name, participantEventHandler),
);

export const startScreenShare = () => callObject.startScreenShare(),
  stopScreenShare = () => callObject.stopScreenShare();

export const $activeSpeaker = atom<string | null>(null);
const activeSpeakerChange = (event?: DailyEventObjectActiveSpeakerChange) => {
  $activeSpeaker.set(event?.activeSpeaker.peerId ?? null);
};
callObject.on('active-speaker-change', activeSpeakerChange);

type ParticipantsComputed = {
  [userId: string]: {
    participant: DailyParticipant;
    distance: number;
    broadcast?: boolean;
  };
};

let prevCallsListen: null | (() => void) = null;

export const $callParticipantsComputed = computed(
  [$logux, $currentMap],
  (client, currentMap) => {
    const { userId } = client.options;

    const $activeCallers = $PlayerCalls(userId, client),
      $broadcasts = $BroadcastCalls(currentMap?.id, client);

    const store = computed(
      [$dailyCallParticipants, $activeCallers, $broadcasts],
      (allParticipants, active, broadcast) => {
        const result: ParticipantsComputed = {};
        for (const [userId, distance] of Object.entries(active)) {
          const participant = allParticipants[userId];

          if (typeof distance === 'number' && participant)
            result[userId] = { distance, participant };
        }

        for (const key in broadcast) {
          if (key === 'id' || !(broadcast as unknown as any)[key]) continue;

          const participant = allParticipants[key];
          if (!participant) continue;

          result[key] = {
            participant,
            distance: 1,
            broadcast: true,
          };
        }

        return result;
      },
    );

    prevCallsListen?.();
    prevCallsListen = computed(
      [$activeCallers, $broadcasts, $dailyCallParticipants],
      (...args) => args,
    ).listen(([activeSubscriptions, broadcasts]) => {
      if (activeSubscriptions.isLoading || broadcasts.isLoading) return;

      const participants = [...Object.values(callObject.participants())];

      for (const [userId, state] of Object.entries(activeSubscriptions)) {
        const participant = participants.find((p) => p.user_name === userId);
        if (!participant) continue;

        // We don't want to handle broadcasted participants here
        if (broadcasts[userId]) continue;

        const subsState =
          typeof state === 'number' ? true : state === 'staged' ? state : false;

        callObject.updateParticipant(participant.session_id, {
          setSubscribedTracks: {
            video: subsState,
            audio: subsState,
            screenVideo: subsState,
          },
        });
      }
      for (const [userId, state] of Object.entries(broadcasts)) {
        const participant = participants.find((p) => p.user_name === userId);
        if (!participant) continue;

        const subsState = !!state;

        callObject.updateParticipant(participant.session_id, {
          setSubscribedTracks: {
            video: subsState,
            audio: subsState,
            screenVideo: subsState,
          },
        });
      }
    });

    return store;
  },
);
export const $localVideo = computed($dailyCallParticipants, (p) => p.local);

export const $dailyRoomJoiner = computed(
  [$currentMap, $mapDailyTokens, $videoStream],
  (map, mapTokens, stream) => {
    if (!map || stream.state !== 'resolved') return;

    if (import.meta.env.VITE_DISABLE_CAMERA) return;

    joinDailyRoom(map.room_url, mapTokens[map.id]);
  },
);
const joinDailyRoom = async (roomUrl: string, token?: string) => {
  await leaveDailyRoom();

  const { id } = $currentUser.get();

  const participants = await callObject.join({
    url: roomUrl,
    userName: id,
    subscribeToTracksAutomatically: false,
    token,
  });
  const { enableCamera, enableMic } = $settings.get();
  callObject.setLocalVideo(!!enableCamera);
  callObject.setLocalAudio(!!enableMic);

  if (participants) $dailyCallParticipants.set(participants);
};
const leaveDailyRoom = async () => {
  if (callObject.meetingState() === 'joined-meeting') return callObject.leave();
};
onSet($gameActive, ({ newValue }) => {
  if (!newValue) leaveDailyRoom();
});

export const setLocalVideo = (res: boolean) => callObject.setLocalVideo(res),
  setLocalVideoDevice = (id: string) =>
    callObject.setInputDevicesAsync({ videoDeviceId: id }),
  setLocalAudioDevice = (id: string) =>
    callObject.setInputDevicesAsync({ audioDeviceId: id }),
  setLocalAudio = (res: boolean) => callObject.setLocalAudio(res);
