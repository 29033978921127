import { Client, status } from '@logux/client';

import { $urlParams } from '@logic/urlParams';
import { onMountOnce } from '@utils/state/onMountOnce';
import { SUBPROTOCOL } from 'shared/server/constants';
import { getMap, init } from 'shared/server/initTypes';
import { noop } from 'shared/utils/function';
import { truthyTypeGuard } from 'shared/utils/ts';

import { addActionTypes, addLoadTypes } from './types';

import { atom, computed } from 'nanostores';

const startClient = (userId: string, token: string) => {
  const serverUrl = import.meta.env.VITE_LOGUX_SERVER_URL;
  truthyTypeGuard(serverUrl);

  const client = new Client({
    subprotocol: SUBPROTOCOL,
    userId,
    token,
    server: serverUrl,
  });
  client.node.setLocalHeaders({ token } as IHeaders);

  if (import.meta.env.DEV) {
    // log(client);
    // badge(client, {
    //   messages: badgeEn,
    //   styles: badgeStyles,
    // });
  }

  client.start();
  return client;
};

export const $logux = atom<Client>();
export const $loguxError = atom<undefined | 'secondTab'>();

onMountOnce($logux, () => {
  const { user_id, auth_token } = $urlParams.get();

  const client = startClient(user_id, auth_token);
  addLoadTypes(client);
  addActionTypes(client);

  $logux.set(client);

  let wasDisconnected = false;
  status(client, (newStatus) => {
    if (wasDisconnected && newStatus !== 'disconnected') {
      $counter.set(Math.random());
      wasDisconnected = false;
    } else if (newStatus === 'disconnected') {
      wasDisconnected = true;
    }
  });

  return noop;
});

const $counter = atom(0);

export const $initActions = computed([$logux, $counter], (client) => {
  if (!client) return;
  const params = $urlParams.get();

  Promise.all([
    client.sync(init.actionCreator(params)),
    client.sync(getMap.actionCreator(params)),
  ]).catch(() => $loguxError.set('secondTab'));
});
