import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { A11yDialog, A11yDialogProps } from 'react-a11y-dialog';

import { useStore } from '@nanostores/react';
import { RecipeVariants } from '@vanilla-extract/recipes';

import { Icon } from '@cmp/ui/Icon';
import { i18n } from '@state/i18n';
import { $modalOpened } from '@state/misc';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { useRandomId } from '@utils/string';

import {
  closeButton,
  container,
  dialog,
  nonDismissableButton,
  overlay,
  title,
} from './Modal.css';

import clsx from 'clsx';
import { animate, timeline } from 'motion';
import { atom, computed } from 'nanostores';

type Props = {
  title: string;
  children: React.ReactNode;
  onHide?: () => void;
  nonDismissable?: boolean;
} & RecipeVariants<typeof dialog>;

export type ModalRef = {
  hide: () => void;
};

type RefType = Parameters<NonNullable<A11yDialogProps['dialogRef']>>[0];

const $messages = i18n('modal', {
  close: 'Close modal',
});

/**
 * Only shows one Modal at a time. If you have multiple `<Modal />` in the tree, they
 * will be shown in a sequence, first to last.
 */
export const Modal = forwardRef<ModalRef, Props>(
  ({ onHide, nonDismissable, padding, maxWidth, design, ...props }, ref) => {
    const t = useStore($messages);

    const isMobile = useIsMobile();

    const id = useRandomId(),
      containerClass = `container--${id}`,
      overlayClass = `overlay--${id}`,
      dialogClass = `dialog--${id}`;

    const currentModalId = useStore($modalIdToShow),
      shouldShow = currentModalId === id;
    useAddMyModalId(id);

    const shouldShowRef = useRef(true);

    const instanceRef = useRef<RefType>();
    const [mounted, setMounted] = useState(false);

    /**
     * Used for non-dismissable modals.
     * Those can only be hidden via calling .hide explicitly.
     */
    const canHide = useRef(false);

    useImperativeHandle(ref, () => ({
      hide: () => {
        canHide.current = true;
        instanceRef.current?.hide();
      },
    }));

    useEffect(() => {
      if (!shouldShow) return;

      const instance = instanceRef.current;
      if (!mounted || !instance) return;
      instance.show();
      if (isMobile) {
        timeline([
          [`.${overlayClass}`, { opacity: 1 }, dur],
          [`.${dialogClass}`, { transform: 'translate(0)' }, dur],
        ]);
      } else {
        timeline([
          [`.${overlayClass}`, { opacity: 1 }, dur],
          [`.${dialogClass}`, { opacity: 1 }, dur],
        ]);
      }
      instance.on('hide', () => {
        if (nonDismissable && !canHide.current) return instance.show();
        const finishedCb = () => {
          shouldShowRef.current = false;
          removeById(id);
          onHide?.();
        };

        if (isMobile) {
          timeline([
            [`.${dialogClass}`, { transform: 'translateY(100%)' }, dur],
            [`.${overlayClass}`, { opacity: 0 }, dur],
          ]).finished.then(finishedCb);
        } else {
          animate(`.${containerClass}`, { opacity: 0 }, dur).finished.then(
            finishedCb,
          );
        }
      });
    }, [
      containerClass,
      dialogClass,
      id,
      isMobile,
      mounted,
      nonDismissable,
      onHide,
      overlayClass,
      shouldShow,
    ]);

    const dialogRefCb = useCallback((i: RefType) => {
      instanceRef.current = i;
      setMounted(!!i);
    }, []);

    if (!shouldShow) return null;

    return (
      <A11yDialog
        {...props}
        id={id}
        dialogRef={dialogRefCb}
        classNames={{
          container: clsx(containerClass, container),
          dialog: clsx(dialogClass, dialog({ padding, design, maxWidth })),
          title,
          overlay: clsx(
            overlayClass,
            overlay({
              nonDismissable: nonDismissable || design === 'bottom',
              back: design === 'bottom' ? void 0 : 'add',
            }),
          ),
          closeButton: clsx(
            closeButton,
            (nonDismissable || padding === 'no') && nonDismissableButton,
          ),
        }}
        closeButtonContent={<Icon name="Close" label={t.close} />}
        closeButtonPosition="last"
      />
    );
  },
);

const $modalIdsStack = atom<string[]>([]),
  $modalIdToShow = computed($modalIdsStack, (list) => list[0]);

$modalIdsStack.listen((val) => $modalOpened.set(val.length > 0));

const addToStack = (id: string) => {
    const curr = $modalIdsStack.get();
    if (!curr.includes(id)) {
      curr.push(id);
      $modalIdsStack.notify();
    }
  },
  useAddMyModalId = (id: string) => useEffect(() => addToStack(id), [id]),
  removeById = (id: string) => {
    const curr = $modalIdsStack.get();
    const i = curr.indexOf(id);
    /**
     * Theoretically this can be simplified to just dropping the first item of the
     * list as only it can be shown at all. But I still went with more explicit way.
     */
    if (i !== -1) {
      curr.splice(i, 1);
      $modalIdsStack.notify();
    }
  };

const dur = { duration: 0.2 };
