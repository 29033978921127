import { SVGProps } from 'react';

export const Question = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM19.5 28.5H16.5V25.5H19.5V28.5ZM22.605 16.875L21.255 18.255C20.175 19.35 19.5 20.25 19.5 22.5H16.5V21.75C16.5 20.1 17.175 18.6 18.255 17.505L20.115 15.615C20.67 15.075 21 14.325 21 13.5C21 11.85 19.65 10.5 18 10.5C16.35 10.5 15 11.85 15 13.5H12C12 10.185 14.685 7.5 18 7.5C21.315 7.5 24 10.185 24 13.5C24 14.82 23.46 16.02 22.605 16.875Z"
      fill="currentColor"
    />
  </svg>
);
