// eslint-disable-next-line @typescript-eslint/ban-types
export const throttle = <Fn extends Function>(func: Fn, timeFrame: number) => {
  let lastTime = 0;
  const throttled = (...args: unknown[]) => {
    const now = Date.now();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
  return throttled as unknown as Fn;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};
export const NoCmp = () => null;

export const memoize = <T extends Record<string, unknown>, R>(
  fn: (args: T) => R,
) => {
  const cache = new Map<string, R>();

  const memoized = (args: T) => {
    const key = JSON.stringify(args);

    if (cache.has(key)) return cache.get(key) as R;
    const val = fn(args) as R;
    cache.set(key, val);
    return val;
  };

  return {
    memoized,
    clear: () => cache.clear(),
  };
};

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
