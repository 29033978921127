import { FC } from 'react';

import { useStore } from '@nanostores/react';

import { i18n } from '@state/i18n';
import { $showHelp } from '@state/misc';
import { Content } from '@ui/Content';
import { Modal } from '@ui/Modal';

import { root } from './HelpModal.css';

type Props = unknown;

const $messages = i18n('helpModal', {
  modalTitle: 'Help modal',
  moveTitle: 'How to move',
  moveDescription: 'Use arrows on the keyboard (←↑→↓) or WASD.',
  callTitle: 'How to call',
  callDescription:
    'When you come closer to someone, a video call starts. You can even join group calls — just give it a try!',
  interactTitle: 'How to interact',
  interactDescription:
    'Some objects on the map are interactive. Just come closer to them!',
  troubleTitle: 'How to troubleshoot mic or camera',
  troubleDescription: 'Please contact support — they will help you.',
});

export const HelpModal: FC<Props> = () => {
  const t = useStore($messages);

  const show = useStore($showHelp);
  if (!show) return null;

  return (
    <Modal title={t.modalTitle} onHide={() => $showHelp.set(false)}>
      <Content className={root}>
        <div>
          <h2>{t.moveTitle}</h2>
          <p>{t.moveDescription}</p>
        </div>
        <div>
          <h2>{t.callTitle}</h2>
          <p>{t.callDescription}</p>
        </div>
        <div>
          <h2>{t.interactTitle}</h2>
          <p>{t.interactDescription}</p>
        </div>
        <div>
          <h2>{t.troubleTitle}</h2>
          <p>{t.troubleDescription}</p>
        </div>
      </Content>
    </Modal>
  );
};
