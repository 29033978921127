import { FC, useRef } from 'react';

import { useStore } from '@nanostores/react';
import { Widget } from '@typeform/embed-react';

import { $iframeData, $typeformData, addFinishedFormId } from '@state/misc';
import { $currentUser } from '@state/user';
import { Modal, ModalRef } from '@ui/Modal';

import { _root } from './IFrame.css';

type Props = unknown;

export const IFrame: FC<Props> = () => {
  const state = useStore($iframeData);

  if (!state) return null;
  const { target, iframeProps } = state,
    { title = '' } = iframeProps;

  return (
    <Modal
      title={title}
      onHide={() => $iframeData.set(void 0)}
      padding="no"
      maxWidth="no"
    >
      <iframe className={_root} src={target} {...iframeProps} title={title} />
    </Modal>
  );
};

export const TypeformFrame: FC<Props> = () => {
  const state = useStore($typeformData),
    user = useStore($currentUser);

  const modalRef = useRef<ModalRef | null>(null);

  if (!state) return null;

  return (
    <Modal
      title="Typeform"
      onHide={() => $typeformData.set(void 0)}
      padding="no"
      maxWidth="no"
      ref={modalRef}
    >
      <Widget
        id={state.id}
        style={{
          width: '90vw',
          height: '90vh',
        }}
        hidden={{ user_id: user.id }}
        onSubmit={() => {
          addFinishedFormId(state.id);
          modalRef.current?.hide();
        }}
        onClose={() => {
          modalRef.current?.hide();
        }}
      />
    </Modal>
  );
};
