import { FC } from 'react';

import { root } from './Content.css';

import clsx from 'clsx';

type Props = JSX.IntrinsicElements['article'];

export const Content: FC<Props> = ({ className, children, ...rest }) => {
  return (
    <article className={clsx(root, className)} {...rest}>
      {children}
    </article>
  );
};
