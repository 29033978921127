import { FC, MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { usePrevious } from 'react-use';

import autoAnimate from '@formkit/auto-animate';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { params } from '@nanostores/i18n';
import { useStore } from '@nanostores/react';

import { PortalBelowApp } from '@cmp/PortalBelowApp';
import { Button } from '@cmp/ui/Button';
import { Presence } from '@cmp/ui/Presence';
import { i18n } from '@state/i18n';
import { $fullscreenOpened } from '@state/misc';
import { Icon } from '@ui/Icon';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { useRandomId } from '@utils/string';

import {
  activeTile,
  fullscreenButton,
  fullscreenVideo,
  iconsTile,
  imgEl,
  imgWrapper,
  nameParagraph,
  nameRow,
  root,
  tile,
  tileName,
  tileRoot,
  videoStyles,
} from './CallTile.css';

import clsx from 'clsx';
import { atom } from 'nanostores';

type Props = {
  name: string;
  avatarUrl: string;
  broadcast?: boolean;
  local?: boolean;
  mute?: boolean;
  active?: boolean;
  screenshare?: MediaStreamTrack;
  audio?: MediaStreamTrack;
  video?: MediaStreamTrack;
};

const $messages = i18n('callTile', {
  userAvatar: params<{ name: string }>('{name} avatar'),
  micOff: 'No audio',
  vidOff: 'No video',
  broadcast: 'Broadcasting',
  sharescreen: 'Sharing screen',

  enterFullscreen: 'Enter fullscreen',
  exitFullscreen: 'Exit fullscreen',
});

const $fullscreenId = atom<string | undefined>();
$fullscreenId.listen((val) => $fullscreenOpened.set(!!val));

export const CallTile: FC<Props> = ({
  active,
  broadcast,
  audio,
  mute,
  video,
  screenshare,
  name,
  local,
  avatarUrl,
}) => {
  const t = useStore($messages);

  const tileId = useRandomId(),
    currentFullScreen = useStore($fullscreenId),
    isFullscreen = tileId === currentFullScreen;
  const [animationParent] = useAutoAnimate<HTMLDivElement>();

  const isMobile = useIsMobile();

  const videoEl = useSetStream<HTMLVideoElement>(video),
    screenshareEl = useSetStream<HTMLVideoElement>(screenshare, isFullscreen),
    audioEl = useSetStream(audio);

  useEffect(() => {
    if (isFullscreen && !screenshare) $fullscreenId.set(void 0);
  }, [isFullscreen, screenshare]);

  const shouldShowScreenshareTile =
    !local && screenshare && !isFullscreen && !isMobile;

  return (
    <div className={root} ref={animationParent}>
      <div className={tileRoot}>
        <div className={clsx(tile, active && activeTile)}>
          {video?.enabled ? (
            <video className={videoStyles} autoPlay playsInline ref={videoEl} />
          ) : (
            <div className={imgWrapper}>
              <img
                className={imgEl}
                src={avatarUrl}
                alt={t.userAvatar({ name })}
              />
            </div>
          )}
          {!local && (
            <>
              <audio autoPlay playsInline ref={audioEl} muted={mute} />
              <div className={tileName}>
                <NameRow name={name} audio={!!audio} video={!!video} />
              </div>
            </>
          )}
        </div>
        <TileIconStates broadcast={broadcast} screenshare={!!screenshare} />
        <Presence
          show={isFullscreen}
          enter={[{ opacity: 1 }]}
          exit={[{ opacity: 0 }]}
        >
          <Button
            className={fullscreenButton['hidden']}
            design="transparent"
            size="sm"
            onClick={() => $fullscreenId.set(void 0)}
          >
            <Icon name="FullscreenOff" label={t.exitFullscreen} />
          </Button>
        </Presence>
      </div>
      {shouldShowScreenshareTile && (
        <div className={tileRoot}>
          <div className={clsx(tile, active && activeTile)}>
            <video
              className={videoStyles}
              autoPlay
              playsInline
              ref={screenshareEl}
            />
            <div className={tileName}>
              <NameRow name={name} audio video />
            </div>
          </div>
          <Button
            className={fullscreenButton['usual']}
            design="transparent"
            size="sm"
            onClick={() => $fullscreenId.set(tileId)}
          >
            <Icon name="FullscreenOn" label={t.enterFullscreen} />
          </Button>
        </div>
      )}
      <PortalBelowApp>
        <Presence
          show={isFullscreen && !!screenshare}
          enter={[{ opacity: 0.99 }]}
          exit={[{ opacity: 0 }]}
        >
          <div className={fullscreenVideo}>
            <video
              className={videoStyles}
              autoPlay
              playsInline
              ref={screenshareEl}
            />
          </div>
        </Presence>
      </PortalBelowApp>
    </div>
  );
};

function useSetStream<T extends HTMLVideoElement | HTMLAudioElement>(
  track: MediaStreamTrack | undefined,
  additional?: unknown,
) {
  const el = useRef<T | null>(null);
  useEffect(() => {
    setTimeout(() => {
      el.current &&
        track?.enabled &&
        (el.current.srcObject = new MediaStream([track]));
    }, 50);
  }, [track, additional]);
  return el as MutableRefObject<T | null>;
}

const iconSize = 12;

const NameRow: FC<{
  name: string;
  audio?: boolean;
  video?: boolean;
}> = ({ name, audio, video }) => {
  const t = useStore($messages);

  return (
    <div className={nameRow}>
      <p className={nameParagraph}>{name}</p>
      {!video && (
        <div>
          <Icon
            width={iconSize}
            height={iconSize}
            name="VideoOff"
            label={t.vidOff}
          />
        </div>
      )}
      {!audio && (
        <div>
          <Icon
            width={iconSize}
            height={iconSize}
            name="MicOff"
            label={t.micOff}
          />
        </div>
      )}
    </div>
  );
};

const TileIconStates: FC<{ broadcast?: boolean; screenshare?: boolean }> = ({
  broadcast,
  screenshare,
}) => {
  const t = useStore($messages);

  const parent = useRef<HTMLDivElement>(null);

  const prevProps = usePrevious({ broadcast, screenshare });
  /**
   * Delaying DOM update according to state to make leave the icons in place
   * when transition happens.
   */
  const [realBroadcast, realScreenshare, show] = useMemo(() => {
    const show = screenshare || broadcast;
    if (screenshare || broadcast) return [broadcast, screenshare, show];

    if (prevProps?.broadcast && !broadcast) return [true, screenshare, show];

    return [broadcast, true, show];
  }, [prevProps, screenshare, broadcast]);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        if (parent.current) autoAnimate(parent.current);
      }, 0);
    }
  }, [parent, show]);

  return (
    <Presence show={show} enter={[{ opacity: 1 }]} exit={[{ opacity: 0 }]}>
      <div className={iconsTile} ref={parent}>
        {realBroadcast && (
          <div>
            <Icon name="BroadcastOn" label={t.broadcast} />
          </div>
        )}
        {realScreenshare && (
          <div>
            <Icon name="ScreenshareOn" label={t.sharescreen} />
          </div>
        )}
      </div>
    </Presence>
  );
};
