import { FC, useEffect, useRef, useState } from 'react';

import { useStore } from '@nanostores/react';

import { $gameActive } from '@state/map';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { truthyTypeGuard } from 'shared/utils/ts';

import { createGame } from 'game/main';

type Props = { className?: string };

export const Game: FC<Props> = ({ className }) => {
  const isMobile = useIsMobile();

  const gameActive = useStore($gameActive),
    canvasRef = useRef<HTMLCanvasElement>(null),
    [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setTimeout(() => setIsMounted(true), 0);
    if (isMounted && gameActive) {
      truthyTypeGuard(canvasRef.current);
      return createGame(canvasRef.current);
    }
  }, [gameActive, isMobile, isMounted]);

  if (!gameActive) return null;

  return <canvas className={className} ref={canvasRef} />;
};
