import { FC, useState } from 'react';
import { useMeasure } from 'react-use';

import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useStore } from '@nanostores/react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { BottomSheet } from '@cmp/BottomSheet';
import { OtherVideos, SelfVideo } from '@cmp/Call';
import { Controls } from '@cmp/Controls';
import { Game } from '@cmp/Game';
import { PortalBelowAppParent } from '@cmp/PortalBelowApp';
import { Icon } from '@cmp/ui/Icon';
import { Presence } from '@cmp/ui/Presence';
import { openLeaderboard, openStage } from '@logic/postMessage';
import { $event } from '@state/misc';
import { useIsMobile } from '@utils/hooks/useIsMobile';

import {
  _belowAll,
  _button,
  _canvas,
  _controls,
  _eventName,
  _navBar,
  _navBarContent,
  _navbarHeight,
  _navBarStartsAt,
  _root,
  _selfVideo,
  _singleStage,
  _stages,
  _videos,
} from './MainGrid.css';

import clsx from 'clsx';

type Props = unknown;

export const MainGrid: FC<Props> = () => {
  const isMobile = useIsMobile();
  const [animationParent] = useAutoAnimate<HTMLDivElement>();

  const canvasFragment = <Game className={_canvas} />;

  if (isMobile)
    return (
      <>
        {canvasFragment}
        <BottomSheet />
        <div className={_navBar}>
          <NavBar />
        </div>
      </>
    );

  return (
    <div className={_root}>
      {canvasFragment}
      <PortalBelowAppParent className={_belowAll} />
      <div className={_selfVideo}>
        <SelfVideo />
      </div>
      <div className={_videos} ref={animationParent}>
        <OtherVideos />
      </div>
      <div className={_controls}>
        <Controls />
      </div>
      <div className={_navBar}>
        <NavBar />
      </div>
    </div>
  );
};

const NavBar: FC = () => {
  const { event } = useStore($event);
  const dt = new Date(event.start_time);

  const [opened, setOpened] = useState(false);

  const [dropContentRef, { height, bottom }] = useMeasure<HTMLDivElement>();

  return (
    <div
      className={_navBarContent}
      style={assignInlineVars({
        [_navbarHeight]: `${opened ? height + bottom : 0}px`,
      })}
    >
      <div className={_eventName}>
        <button className={_button} onClick={() => setOpened(!opened)}>
          <Icon name={opened ? 'MenuOpened' : 'Menu'} label="Menu" />
        </button>
        <p>{event.name}</p>
      </div>
      <button className={_button} onClick={openLeaderboard}>
        <Icon name="Leaderboard" label="Leaderboard" width={24} height={24} />
      </button>

      <p className={_navBarStartsAt}>
        Starts at {dt.getHours()}:{dt.getMinutes()}
      </p>

      <Presence
        show={opened}
        enter={[{ opacity: 1 }, { delay: 0.3 }]}
        exit={[{ opacity: 0 }]}
      >
        <div className={_stages} ref={dropContentRef}>
          {event.stages
            .filter(({ display_type }) => display_type !== 'connect')
            .map(({ name, uuid }) => (
              <div key={uuid}>
                <button
                  className={clsx(_button, _singleStage)}
                  onClick={() => openStage(uuid)}
                >
                  <Icon name="Lobby" label={`Lobby ${name}`} />
                  <span>{name}</span>
                </button>
              </div>
            ))}
        </div>
      </Presence>
    </div>
  );
};
