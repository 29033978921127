import { FC } from 'react';

import { useStore } from '@nanostores/react';
import { RecipeVariants } from '@vanilla-extract/recipes';

import { i18n } from '@state/i18n';

import { Icon } from '../Icon';

import { dropStyle, iconStyle, root, select } from './Select.css';

import clsx from 'clsx';

type Props = JSX.IntrinsicElements['select'] & {
  icon?: React.ComponentProps<typeof Icon>;
} & RecipeVariants<typeof select>;

const $messages = i18n('select', {
  open: 'Open select',
});

export const Select: FC<Props> = ({ className, icon, fullWidth, ...props }) => {
  const t = useStore($messages);

  return (
    <div className={clsx(root)}>
      {icon && <Icon className={iconStyle} {...icon} />}
      <select
        {...props}
        className={clsx(className, select({ fullWidth, withIcon: !!icon }))}
      />
      <Icon className={dropStyle} name="Dropdown" label={t.open} />
    </div>
  );
};
