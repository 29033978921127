import { SVGProps } from 'react';

export const ScreenshareOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 5.37842L5.50612 8.43557L6.49388 9.56443L8.25 8.02783V12H9.75V8.02783L11.5061 9.56443L12.4939 8.43557L9 5.37842Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 4.5C1.5 3.67157 2.17157 3 3 3H15C15.8284 3 16.5 3.67157 16.5 4.5V13.5C16.5 14.3284 15.8284 15 15 15H3C2.17157 15 1.5 14.3284 1.5 13.5V4.5ZM3 4.5H15V13.5H3V4.5Z"
      fill="currentColor"
    />
  </svg>
);

export const ScreenshareOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5858 20L21.2929 22.7071L22.7071 21.2928L2.70711 1.29285L1.29289 2.70706L2.9092 4.32337C2.36183 4.68021 2 5.29785 2 6V18C2 19.1046 2.89543 20 4 20H18.5858ZM16.5858 18L13 14.4142V16H11V12.4142L10.0878 11.502L8.6585 12.7526L7.3415 11.2474L8.67043 10.0846L4.58583 6H4V18H16.5858ZM8 4L10 6H20V16L22 18V6C22 4.89543 21.1046 4 20 4H8ZM11.558 7.55799L16 12L16.6585 11.2474L12 7.17123L11.558 7.55799Z"
      fill="currentColor"
    />
  </svg>
);
