import { PreloaderScene } from './scenes/preload';
import { TileMapScene } from './scenes/tileMap';

import Phaser from 'phaser';

export const createGame = (canvas: HTMLCanvasElement | null) => {
  if (!canvas) return;
  const game = new Phaser.Game({
    type: Phaser.WEBGL,
    canvas,

    scale: {
      mode: Phaser.Scale.RESIZE,
      width: '100%',
      height: '100%',
    },
    physics: {
      default: 'arcade',
      arcade: {
        gravity: { y: 0 },
        debug: import.meta.env.DEV,
      },
    },
    scene: [PreloaderScene, TileMapScene],
  });

  return () => game.destroy(false);
};
