import { SVGProps } from 'react';

export const Leaderboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6924 9.56384C19.6924 13.4298 16.5584 16.5638 12.6924 16.5638C8.82639 16.5638 5.69238 13.4298 5.69238 9.56384C5.69238 5.69785 8.82639 2.56384 12.6924 2.56384C16.5584 2.56384 19.6924 5.69785 19.6924 9.56384Z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M8.69238 15.5638V20.8972L12.6924 19.4972L16.6924 20.8972V15.5638"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M12.2389 6.54032C12.4181 6.15446 12.9667 6.15446 13.1459 6.54032L13.7085 7.75176C13.7813 7.90864 13.9301 8.01672 14.1018 8.03753L15.4278 8.19824C15.8501 8.24942 16.0197 8.77115 15.7081 9.06081L14.7298 9.97023C14.6031 10.088 14.5463 10.2629 14.5795 10.4326L14.8364 11.7434C14.9183 12.1609 14.4745 12.4833 14.1027 12.2765L12.9355 11.6271C12.7843 11.543 12.6004 11.543 12.4493 11.6271L11.2821 12.2765C10.9103 12.4833 10.4665 12.1609 10.5483 11.7434L10.8052 10.4326C10.8385 10.2629 10.7817 10.088 10.655 9.97023L9.67671 9.06081C9.36511 8.77115 9.53462 8.24942 9.95698 8.19824L11.283 8.03753C11.4547 8.01672 11.6034 7.90864 11.6763 7.75176L12.2389 6.54032Z"
      fill="currentColor"
    />
  </svg>
);
