import { SVGProps } from 'react';

export const VolumeOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 13.5007V22.5007H10.5L18 30.0007V6.0007L10.5 13.5007H4.5ZM15 13.2457V22.7557L11.745 19.5007H7.5V16.5007H11.745L15 13.2457ZM24.75 18.0007C24.75 15.3457 23.22 13.0657 21 11.9557V24.0307C23.22 22.9357 24.75 20.6557 24.75 18.0007ZM21 4.8457V7.9357C25.335 9.2257 28.5 13.2457 28.5 18.0007C28.5 22.7557 25.335 26.7757 21 28.0657V31.1557C27.015 29.7907 31.5 24.4207 31.5 18.0007C31.5 11.5807 27.015 6.2107 21 4.8457Z"
      fill="currentColor"
    />
  </svg>
);

export const VolumeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.50953 4.40234L4.39453 6.51734L10.9345 13.0573L10.4995 13.5073H4.49953V22.5073H10.4995L17.9995 30.0073V20.1223L24.2695 26.3923C23.2945 27.1273 22.1995 27.7123 20.9995 28.0573V31.1473C23.0095 30.6973 24.8545 29.7673 26.4145 28.5223L29.4895 31.5973L31.6045 29.4823L6.50953 4.40234ZM14.9995 22.7623L11.7445 19.5073H7.49953V16.5073H11.7445L13.0645 15.1873L14.9995 17.1223V22.7623ZM28.4995 18.0073C28.4995 19.2373 28.2745 20.4223 27.8845 21.5173L30.1795 23.8123C31.0195 22.0573 31.4995 20.0923 31.4995 18.0073C31.4995 11.5873 27.0145 6.21734 20.9995 4.85234V7.94234C25.3345 9.23234 28.4995 13.2523 28.4995 18.0073ZM17.9995 6.00734L15.1795 8.82734L17.9995 11.6473V6.00734ZM24.7495 18.0073C24.7495 15.3523 23.2195 13.0723 20.9995 11.9623V14.6473L24.7195 18.3673C24.7345 18.2473 24.7495 18.1273 24.7495 18.0073Z"
      fill="#D7373F"
    />
  </svg>
);
