import { FC, PropsWithChildren } from 'react';

import { useStore } from '@nanostores/react';

import { Presence } from '../Presence';

import { _root, _singleToast } from './Toasts.css';

import { atom } from 'nanostores';

const $toasts = atom<React.ReactNode[]>([]);
export const addToast = (content: React.ReactNode, timeout = 3000) => {
  const curr = $toasts.get();
  curr.push(content);
  $toasts.notify();

  setTimeout(() => {
    const curr = $toasts.get();
    const i = curr.indexOf(content);
    if (i !== -1) {
      curr.splice(i, 1);
      $toasts.notify();
    }
  }, timeout);
};

export const Toasts: FC = () => {
  const toasts = useStore($toasts);

  return (
    <div className={_root}>
      {toasts.map((content, i) => (
        <Toast key={i}>{content}</Toast>
      ))}
    </div>
  );
};

const Toast: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <Presence show enter={[{ opacity: 1 }]} exit={[{ opacity: 0 }]}>
    <div className={_singleToast} aria-live="polite">
      {children}
    </div>
  </Presence>
);
