import { SVGProps } from 'react';

export const VideoOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 15.72V9C27 7.35 25.65 6 24 6H6C4.35 6 3 7.35 3 9V27C3 28.65 4.35 30 6 30H24C25.65 30 27 28.65 27 27V20.28L33 26.25V9.75L27 15.72ZM24 27H6V9H24V27Z"
      fill="currentColor"
    />
    <path
      d="M15 18C16.6569 18 18 16.6569 18 15C18 13.3431 16.6569 12 15 12C13.3431 12 12 13.3431 12 15C12 16.6569 13.3431 18 15 18Z"
      fill="currentColor"
    />
    <path
      d="M21 23.145C21 21.93 20.28 20.85 19.17 20.37C17.895 19.815 16.485 19.5 15 19.5C13.515 19.5 12.105 19.815 10.83 20.37C9.72 20.85 9 21.93 9 23.145V24H21V23.145Z"
      fill="currentColor"
    />
  </svg>
);

export const VideoOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.98969 8.56969L7.98969 6.56969L3.83969 2.42969L2.42969 3.83969L5.15969 6.56969H4.42969C3.87969 6.56969 3.42969 7.01969 3.42969 7.56969V17.5697C3.42969 18.1197 3.87969 18.5697 4.42969 18.5697H16.4297C16.6397 18.5697 16.8197 18.4897 16.9797 18.3897L20.1597 21.5697L21.5697 20.1597L12.7097 11.2997L9.98969 8.56969ZM5.42969 16.5697V8.56969H7.15969L15.1597 16.5697H5.42969ZM15.4297 8.56969V11.1797L21.4297 17.1797V7.06969L17.4297 11.0697V7.56969C17.4297 7.01969 16.9797 6.56969 16.4297 6.56969H10.8197L12.8197 8.56969H15.4297Z"
      fill="#D7373F"
    />
  </svg>
);
