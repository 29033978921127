import { FC, PropsWithChildren } from 'react';

import { assignInlineVars } from '@vanilla-extract/dynamic';

import { bgColorVar, root } from './Overlay.css';

import clsx from 'clsx';

type Props = PropsWithChildren<{ bgColor?: string; className?: string }>;

export const Overlay: FC<Props> = ({
  children,
  bgColor = '#000',
  className,
}) => {
  return (
    <div
      className={clsx(root, className)}
      style={assignInlineVars({ [bgColorVar]: bgColor })}
    >
      {children}
    </div>
  );
};
