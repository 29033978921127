import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { truthyTypeGuard } from 'shared/utils/ts';

import { root } from './PortalBelowApp.css';

import clsx from 'clsx';

export const PortalBelowAppParent: FC<{ className?: string }> = ({
  className,
}) => {
  return <div className={clsx(root, className)} />;
};

export const PortalBelowApp: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [show, setShow] = useState(false);
  useEffect(() => setShow(true), []);

  if (!show) return null;

  const el = document.querySelector(`.${root}`);
  truthyTypeGuard(el);
  return createPortal(children, el);
};
