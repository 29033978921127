import { FC, useEffect } from 'react';

import { params } from '@nanostores/i18n';
import { useStore } from '@nanostores/react';

import { CallTile } from '@cmp/Call/CallTile';
import { ButtonLink } from '@cmp/ui/Button';
import { $devicesByType, $videoStream } from '@logic/videoStream';
import { i18n } from '@state/i18n';
import { $settings } from '@state/settings';
import { $currentUser } from '@state/user';
import { Select } from '@ui/Select';

import rejectedIcons from './static/rejected.svg';

import { _rejected, avatar, callTile } from './Settings.css';
import { sprinkles } from '@styles/sprinkles.css';

type Props = {
  setVideoAvailable?: () => void;
  setVideoRejected?: () => void;
  pendingSlot?: React.ReactNode;
};

const $messages = i18n('settings', {
  giveAccess:
    'To join the lobby, please give access to your camera and microphone.',
  avatarAlt: params<{ name: string }>('{name} avatar'),
});

export const Settings: FC<Props> = ({
  setVideoAvailable,
  setVideoRejected,
  pendingSlot,
}) => {
  const t = useStore($messages);
  const { stream, state } = useStore($videoStream);
  const user = useStore($currentUser);

  const tracks = {
    audio: stream?.getAudioTracks()[0],
    video: stream?.getVideoTracks()[0],
  };

  useEffect(() => {
    if (state === 'resolved' && tracks.video) setVideoAvailable?.();
    else if (state === 'rejected') setVideoRejected?.();
  }, [setVideoAvailable, setVideoRejected, state, tracks.video]);

  return (
    <div>
      {state === 'pending' ? (
        pendingSlot || (
          <div className={_rejected}>
            <p className={sprinkles({ textAlign: 'center' })}>{t.giveAccess}</p>
          </div>
        )
      ) : state === 'rejected' ? (
        <Rejected />
      ) : (
        <div>
          <div className={callTile}>
            <CallTile name="" avatarUrl={user.avatar_url} local {...tracks} />
            <p>{user.name}</p>
            <img
              className={avatar}
              src={user.avatar_url}
              alt={t.avatarAlt({ name: user.name })}
            />
          </div>
          <DevicesInputForm />
        </div>
      )}
    </div>
  );
};

const $rejectedMessages = i18n('settings:rejected', {
  rejected:
    'Unfortunately, you cannot join the lobby without camera and microphone.',
  rejectedIconAlt: 'Camera and microphone permissions were denied',
  contact: 'Contact support',
});

const Rejected: FC = () => {
  const t = useStore($rejectedMessages);

  return (
    <div className={_rejected}>
      <img src={rejectedIcons} alt={t.rejectedIconAlt} />
      <p className={sprinkles({ textAlign: 'center' })}>{t.rejected}</p>
      <ButtonLink
        href="mailto:info@vstage.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t.contact}
      </ButtonLink>
    </div>
  );
};

const mediaKindToIconAndSetting: Record<
  MediaDeviceKind,
  [
    keyof IStoreValue<typeof $settings>,
    React.ComponentProps<typeof Select>['icon'],
  ]
> = {
  audioinput: ['audioInput', { name: 'MicOn', label: 'Audio input' }],
  videoinput: ['videoInput', { name: 'VideoOn', label: 'Video input' }],
  audiooutput: ['audioOutput', { name: 'VolumeOn', label: 'Audio output' }],
};

const DevicesInputForm: FC = () => {
  const devicesByType = useStore($devicesByType);
  const settings = useStore($settings);

  if (!devicesByType) return null;

  return (
    <div
      className={sprinkles({
        display: 'flex',
        flexDirection: 'column',
        gap: 'mdP',
      })}
    >
      {Object.entries(devicesByType).map(([kind, devices]) => {
        const [key, icon] = mediaKindToIconAndSetting[kind as MediaDeviceKind];

        return (
          <Select
            key={kind}
            fullWidth
            icon={icon}
            value={settings[key]}
            onChange={(e) => {
              $settings.setKey(key, e.currentTarget.value);
            }}
          >
            {devices.map((device) => (
              <option key={device.id} value={device.id}>
                {device.label}
              </option>
            ))}
          </Select>
        );
      })}
    </div>
  );
};
