import { FC, PropsWithChildren, useMemo } from 'react';

import { changeSyncMapById } from '@logux/client';
import { useClient, useSync } from '@logux/client/react';
import { useStore } from '@nanostores/react';

import { Button } from '@cmp/ui/Button';
import { Icon } from '@cmp/ui/Icon';
import { Tooltip } from '@cmp/ui/Tooltip';
import { $BroadcastCalls } from '@services/player';
import {
  $localVideo,
  startScreenShare,
  stopScreenShare,
} from '@state/dailyCalls';
import { i18n } from '@state/i18n';
import { $currentMap } from '@state/map';
import { $showHelp, $showSettings } from '@state/misc';
import { $settings, toggleSetting } from '@state/settings';
import { $currentUser } from '@state/user';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { BroadcastCalls } from 'shared/server/playerTypes';

import { group, root } from './Controls.css';
import { sprinkles } from '@styles/sprinkles.css';

type Props = unknown;

const $messages = i18n('leftMenu', {
  unmute: 'Unmute all',
  mute: 'Mute all',
  micOff: 'Disable mic',
  micOn: 'Enable mic',
  vidOff: 'Disable video',
  vidOn: 'Enable video',

  broadcastOff: 'Stop broadcast',
  broadcastOn: 'Start broadcast',

  screenshareOff: 'Stop screen share',
  screenshareOn: 'Start screen share',

  settings: 'Settings',
  support: 'Support',
  goToMainStage: 'Go to mainstage',
});
const _red = sprinkles({ color: 'red' });

export const Controls: FC<Props> = () => {
  const t = useStore($messages);

  return (
    <div className={root}>
      <DevicesSettings />
      {/* Leaving this out of product for the time being */}
      {/* <MediaSettings /> */}

      <Group>
        <Tooltip placement="right" label={t.settings}>
          <Button design="transparent" onClick={() => $showSettings.set(true)}>
            <Icon name="Cog" label={t.settings} />
          </Button>
        </Tooltip>
        <Tooltip placement="right" label={t.support}>
          <Button design="transparent" onClick={() => $showHelp.set(true)}>
            <Icon name="Question" label={t.support} />
          </Button>
        </Tooltip>
      </Group>
    </div>
  );
};

const Group: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className={group}>{children}</div>
);

const DevicesSettings: FC = () => {
  const t = useStore($messages);
  const settings = useStore($settings);

  const settingsBtnConfig = useMemo(
    () =>
      [
        // {
        //   setting: 'muteAll',
        //   _true: {
        //     icon: <Icon name="VolumeOff" label={t.unmute} />,
        //     label: t.unmute,
        //   },
        //   _false: {
        //     icon: <Icon name="VolumeOn" label={t.mute} />,
        //     label: <span className={_red}>{t.mute}</span>,
        //   },
        // },
        {
          setting: 'enableMic',
          _true: {
            icon: <Icon name="MicOn" label={t.micOff} />,
            label: <span className={_red}>{t.micOff}</span>,
          },
          _false: {
            icon: <Icon name="MicOff" label={t.micOn} />,
            label: t.micOn,
          },
        },
        {
          setting: 'enableCamera',
          _true: {
            icon: <Icon name="VideoOn" label={t.vidOff} />,
            label: <span className={_red}>{t.vidOff}</span>,
          },
          _false: {
            icon: <Icon name="VideoOff" label={t.vidOn} />,
            label: t.vidOn,
          },
        },
      ] as const,
    [t],
  );

  return (
    <Group>
      {settingsBtnConfig.map(({ setting, _true, _false }) => {
        const { icon, label } = settings[setting] ? _true : _false;
        return (
          <Tooltip placement="right" label={label} key={setting}>
            <Button
              design="transparent"
              onClick={() =>
                $settings.setKey(setting, toggleSetting(settings[setting]))
              }
            >
              {icon}
            </Button>
          </Tooltip>
        );
      })}
    </Group>
  );
};

const MediaSettings: FC = () => {
  const { id: userId, is_moderator } = useStore($currentUser);

  const isMobile = useIsMobile();

  if (isMobile && !is_moderator) return null;

  return (
    <Group>
      {is_moderator && <BroadcastButton userId={userId} />}
      {!isMobile && <ScreenshareButton />}
    </Group>
  );
};

const BroadcastButton: FC<{ userId: string }> = ({ userId }) => {
  const t = useStore($messages);
  const { id: mapId } = useStore($currentMap),
    client = useClient();

  const broadcaster = useSync($BroadcastCalls, mapId),
    isBroadcasting = (broadcaster as BroadcastCalls)[userId],
    somebodyBroadcasts = Object.entries(broadcaster as BroadcastCalls).some(
      ([key, value]) =>
        key !== 'id' && key !== 'isLoading' && key !== userId && value,
    );

  const onClick = () => {
    changeSyncMapById(
      client,
      $BroadcastCalls,
      mapId,
      isBroadcasting ? { [userId]: null } : { [userId]: true },
    );
  };

  const label = isBroadcasting ? t.broadcastOff : t.broadcastOn;

  return (
    <Tooltip
      placement="right"
      label={<span className={isBroadcasting ? _red : void 0}>{label}</span>}
    >
      <Button
        design="transparent"
        onClick={onClick}
        disabled={somebodyBroadcasts}
      >
        <Icon
          name={isBroadcasting ? 'BroadcastOn' : 'BroadcastOff'}
          label={label}
        />
      </Button>
    </Tooltip>
  );
};

const ScreenshareButton: FC = () => {
  const t = useStore($messages);
  const localVideo = useStore($localVideo),
    isScreensharing = !!localVideo?.screenVideoTrack;

  const label = isScreensharing ? t.screenshareOff : t.screenshareOn;

  return (
    <Tooltip
      placement="right"
      label={<span className={isScreensharing ? _red : void 0}>{label}</span>}
    >
      <Button
        design="transparent"
        className={isScreensharing ? void 0 : _red}
        onClick={isScreensharing ? stopScreenShare : startScreenShare}
      >
        <Icon
          name={isScreensharing ? 'ScreenshareOn' : 'ScreenshareOff'}
          label={label}
        />
      </Button>
    </Tooltip>
  );
};
