import { atom, onSet, onStop } from 'nanostores';

export const getResizeAtom = <T extends HTMLElement>() => {
  const elAtom = atom<T>();

  const sizeAtom = atom<DOMRectReadOnly | undefined>();
  const observer = new window.ResizeObserver((entries) => {
    if (entries[0]) sizeAtom.set(entries[0].contentRect);
  });
  onSet(elAtom, ({ newValue }) => observer.observe(newValue));
  onStop(sizeAtom, () => observer.disconnect());

  return [
    (ref: T | null) => {
      if (ref) elAtom.set(ref);
    },
    sizeAtom,
  ] as const;
};
