import { assignInlineVars } from '@vanilla-extract/dynamic';

import { $callParticipantsComputed } from '@state/dailyCalls';
import { getResizeAtom } from '@utils/state/resizeAtom';

import { rootBotPaddingVar, rootYHeightVar } from './BottomSheet.css';

import { atom, computed, ReadableAtom } from 'nanostores';

const [oneLineRef, $oneLineRect] = getResizeAtom(),
  [attendeesRef, $attendeesRect] = getResizeAtom();

export { oneLineRef, attendeesRef };

let $attendeesCount: ReadableAtom<number>;
let currCount: number;
$callParticipantsComputed.listen(($s) => {
  $attendeesCount = computed($s, (v) => Object.keys(v).length);
  $attendeesCount.listen((v) => (currCount = v + 1));
});

const $state = atom<'smart' | 'oneLine' | 'noVids'>('smart');
let currState: IStoreValue<typeof $state>,
  goingDown = true;
$state.listen((val) => (currState = val));
const goUp = () => {
  if (currState == 'smart') return;

  const skipOneLineState = currCount <= 3;
  if (currState == 'noVids' && !skipOneLineState) $state.set('oneLine');
  else {
    goingDown = true;
    $state.set('smart');
  }
};
const goDown = () => {
  if (currState == 'noVids') return;

  const skipOneLineState = currCount <= 3;
  if (currState == 'smart' && !skipOneLineState) $state.set('oneLine');
  else {
    $state.set('noVids');
    goingDown = false;
  }
};

export const onClickHandler: React.MouseEventHandler<HTMLButtonElement> = () =>
  goingDown ? goDown() : goUp();

const additionalBottomPadding = 100;
const $additionalTransform = atom(0);
export const rootRef = (el: HTMLElement | null) => {
  if (!el) return;
  let start = 0,
    diff = 0;

  let prevTransition = '';

  el.addEventListener('touchstart', (e) => {
    start = e.touches?.[0]?.clientY || 0;
    prevTransition = getComputedStyle(el).getPropertyValue('transition');
    el.style.transition = 'none';
  });
  el.addEventListener('touchmove', (e) => {
    e.preventDefault();
    const newVal = e.touches?.[0]?.clientY;
    if (!newVal) return;

    diff = newVal - start;

    const abs = Math.abs(diff);

    let realDiff: number = diff;
    const breakDistance = additionalBottomPadding * 0.7;
    if (abs >= breakDistance) {
      const mult = diff > 0 ? 1 : -1;
      realDiff = ((breakDistance * 7) / (abs * -1) + breakDistance) * mult;
    }

    $additionalTransform.set(realDiff);
  });
  el.addEventListener('touchend', () => {
    el.style.transition = prevTransition;
    setTimeout(() => {
      if (Math.abs(diff) >= 50) {
        if (diff > 0) goDown();
        else goUp();
      }

      diff = 0;
      $additionalTransform.set(0);
    });
  });
};

export const $bottomSheetHeightData = computed(
  [$oneLineRect, $attendeesRect, $additionalTransform, $state],
  (oneLineRect, attendeesRect, transform, state) => {
    if (!oneLineRect || !attendeesRect) return;

    const height =
      state === 'smart'
        ? additionalBottomPadding
        : state === 'oneLine'
        ? additionalBottomPadding + attendeesRect.height - oneLineRect.height
        : additionalBottomPadding + attendeesRect.height;

    return {
      visibleHeight: height,
      style: assignInlineVars({
        [rootYHeightVar]: height + transform + 'px',
        [rootBotPaddingVar]: additionalBottomPadding + 'px',
      }),
    };
  },
);
