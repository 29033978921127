import { Client } from '@logux/client';

import { changeMapId } from '@logic/urlParams';
import { $logux } from '@services/logux';
import { $currentMap } from '@state/map';
import { $event } from '@state/misc';
import { $currentUser } from '@state/user';
import {
  getMap,
  GetMapRes,
  init,
  InitRes,
  WithType,
} from 'shared/server/initTypes';
import { delay } from 'shared/utils/function';

export const addLoadTypes = (client: Client) => {
  client.type<WithType<InitRes>>(init.resType, ({ user, event }) => {
    $event.set(event);
    $currentUser.set(user);
  });

  client.type<WithType<GetMapRes>>(getMap.resType, async ({ map }) => {
    console.log('fetched map', map.id);
    $currentMap.set(map);
  });
};

export const fetchMap = async (map_id: string) => {
  const client = $logux.get();

  changeMapId(map_id);
  await delay(500);
  return client.sync(getMap.actionCreator({ map_id }));
};
