import { changeSyncMapById } from '@logux/client';

import { openStage } from '@logic/postMessage';
import { $logux } from '@services/logux';
import { $PlayerPositions } from '@services/player';
import { fetchMap } from '@services/types';
import { $currentMap, $transitioningToMap } from '@state/map';
import {
  $event,
  $finishedForms,
  $iframeData,
  $typeformData,
} from '@state/misc';
import { $currentUser } from '@state/user';
import { sitAtTable } from 'shared/server/actionTypes';
import { isNumber } from 'shared/utils/number';

import {
  IFrameActionObj,
  PositioningObj,
  TableActionObj,
  TypeformActionObj,
  ZoneClass,
  ZoneClassCreator,
} from './types';

import { Pos } from 'game/utils/pos';
import { map } from 'nanostores';
import Phaser from 'phaser';

export const $positioning = map<{
  spawnPoint: Pos | undefined;
  tileWidth: number;
  tileHeight: number;
}>();

export class Objects {
  public group: Phaser.Physics.Arcade.Group;

  private int: number;

  constructor(scene: Phaser.Scene, tilemap: Phaser.Tilemaps.Tilemap) {
    $positioning.setKey('tileHeight', tilemap.tileHeight);
    $positioning.setKey('tileWidth', tilemap.tileWidth);

    const objects = tilemap
      .getObjectLayerNames()
      .flatMap((layerName) => tilemap.getObjectLayer(layerName).objects);

    this.group = scene.physics.add.group(
      objects.map((obj) => {
        let Klass: ZoneClassCreator;
        switch (obj.name.toLowerCase()) {
          case 'iframe':
            Klass = IFrame;
            break;

          case 'table':
            Klass = Table;
            break;

          case 'typeform':
            Klass = Typeform;
            break;

          case 'mainstage':
            Klass = Mainstage;
            break;

          case 'spawn':
          case 'portal':
            Klass = Portal;
            break;

          default:
            throw new Error('Unknown object type');
        }
        if (!obj.x || !obj.width || !obj.height || !obj.y)
          throw new Error('object must have coords');

        const zone = new Klass(
          scene,
          obj.x + obj.width / 2,
          obj.y + obj.height / 2,
          obj.width,
          obj.height,
        );
        zone.setProperties(obj.properties);
        zone.init();

        scene.physics.world.enable(zone);
        const body = zone.body as Phaser.Physics.Arcade.Body;
        body.setAllowGravity(false);
        body.moves = false;
        return zone;
      }),
    );

    this.int = window.setInterval(() => {
      const now = performance.now();
      for (const [zone, time] of this.timers.entries()) {
        if (time && now - time > 100) {
          zone.handleCollisionEnd();
          this.timers.set(zone, null);
        }
      }
    }, 150);
  }

  private prevZoneCollided: ZoneClass | null = null;
  private timers: Map<ZoneClass, number | null> = new Map();
  handleCollision(
    _: unknown,
    _zone: Phaser.Types.Physics.Arcade.GameObjectWithBody,
  ) {
    const zone = _zone as ZoneClass;

    const sameBody = this.prevZoneCollided === zone,
      now = performance.now(),
      prevWasLongAgo = now - (this.timers.get(zone) || 0) > 350;

    if (!sameBody || prevWasLongAgo) {
      zone.handleCollision();
    }
    this.prevZoneCollided = zone;
    this.timers.set(zone, now);
  }

  destroy() {
    window.clearInterval(this.int);
  }
}

class Portal extends ZoneClass {
  public target?: { mapId: string } | { mapId: string; x: number; y: number };

  private setNewSpawnPoint(coords?: ITwoPointCoords) {
    const { tileWidth, tileHeight } = $positioning.get();
    const newSpawnPoint = new Pos(coords ?? [0, 0], tileWidth, tileHeight);
    if (!coords) newSpawnPoint.coords = [this.x, this.y];
    $positioning.setKey('spawnPoint', newSpawnPoint);
  }

  props: PositioningObj['properties'] = [];
  init() {
    // It's a spawn point!
    if (!this.props?.length) {
      if (!$positioning.get().spawnPoint) {
        this.setNewSpawnPoint();
      }

      return;
    }

    const { portalTargetToMapId } = $currentMap.get().mapData;

    // It's a portal, presumably
    let target: string | null = null,
      x: number | undefined = void 0,
      y: number | undefined = void 0;
    for (const prop of this.props) {
      if (!prop) continue;

      if (prop.name === 'target') target = prop.value;
      else if (prop.name === 'toX') x = prop.value;
      else if (prop.name === 'toY') y = prop.value;
    }
    if (!target) throw new Error('Define target for the portal');
    const mapId = portalTargetToMapId[target];
    if (!mapId)
      throw new Error('Define this target in the mapIdToMapUuid object');

    if (isNumber(x) && isNumber(y)) this.target = { mapId, x, y };
    else this.target = { mapId };
  }

  async handleCollision() {
    if (!this.target) return;

    const { mapId } = this.target;
    if ('x' in this.target) {
      this.setNewSpawnPoint([this.target.x, this.target.y]);
    } else {
      // Resetting spawn point
      $positioning.setKey('spawnPoint', undefined);
    }

    $transitioningToMap.set(true);
    await changeSyncMapById(
      $logux.get(),
      $PlayerPositions,
      $currentMap.get().id,
      { [$currentUser.get().id]: null },
    );
    await fetchMap(mapId);
    this.scene.scene.transition({ target: 'Preloader', duration: 1 });
  }
}

class IFrame extends ZoneClass {
  props: IFrameActionObj['properties'] = [];

  url!: string;
  iframeProps: Record<string, string> = {};
  init() {
    for (const prop of this.props) {
      if (!prop) continue;

      if (prop.name === 'url') {
        this.url = prop.value;
      } else {
        this.iframeProps[prop.name] = prop.value;
      }
    }

    if (!this.url) throw new Error('Define url for the iframe obj');
  }

  handleCollision() {
    $iframeData.set({ target: this.url, iframeProps: this.iframeProps });
  }
}

class Table extends ZoneClass {
  props: TableActionObj['properties'] = [];

  id!: string;
  init() {
    for (const prop of this.props) {
      if (!prop) continue;

      if (prop.name === 'id') {
        this.id = prop.value;
      }
    }

    if (!this.id) throw new Error('Define id for the table obj');
  }

  // private graphic: Phaser.GameObjects.GameObject | null = null;
  handleCollision() {
    // if (this.graphic) this.graphic.destroy();

    /**
     * https://www.stephengarside.co.uk/blog/phaser-3-center-text-in-middle-of-screen/
     * https://github.com/photonstorm/phaser3-examples/blob/master/public/src/display/masks/spotlight.js
     *
     */
    // const rt = this.scene.add.renderTexture(-1000, -1000, 5000, 5000);
    // rt.fill(0x000000, 0.5);

    $logux.get().sync({ type: sitAtTable, id: this.id, state: true });
  }

  handleCollisionEnd() {
    // this.graphic?.destroy();
    // this.graphic = null;
    $logux.get().sync({ type: sitAtTable, id: this.id, state: false });
  }
}

class Typeform extends ZoneClass {
  props: TypeformActionObj['properties'] = [];

  id!: string;
  init() {
    for (const prop of this.props) {
      if (!prop) continue;

      if (prop.name === 'id') {
        this.id = prop.value;
      }
    }

    if (!this.id) throw new Error('Define ID for the typeform obj');
  }

  handleCollision() {
    if ($finishedForms.get().includes(this.id)) {
      return;
    }

    $typeformData.set({ id: this.id });
  }
}

class Mainstage extends ZoneClass {
  handleCollision() {
    const { event } = $event.get();
    const mainstage = event.stages.find(
      (stage) => stage.display_type === 'dancefloor',
    );

    if (!mainstage) {
      console.error('no dancefloor found among stages');
      return;
    }

    openStage(mainstage.uuid);
  }
}
