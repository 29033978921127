import { onMount, ReadableAtom } from 'nanostores';

export const onMountOnce = <T>(store: ReadableAtom<T>, fn: () => unknown) => {
  let alreadyRun = false;
  onMount(store, () => {
    if (alreadyRun) return;

    alreadyRun = true;
    return fn();
  });
};
