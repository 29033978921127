import { persistentAtom } from '@nanostores/persistent';

import { computed } from 'nanostores';

export function createPersistedCommaSplitAtom(lsName: string) {
  const $baseStore = persistentAtom<string>(lsName, '');

  const $store = computed($baseStore, (asString) =>
    (asString || '').split(',').filter(Boolean),
  );
  const add = (id: string) => {
    const curr = $baseStore.get();
    $baseStore.set(curr + ',' + id);
  };

  return { $store, add };
}
