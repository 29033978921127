import { $settings } from '@state/settings';
import { onMountOnce } from '@utils/state/onMountOnce';
import { noop } from 'shared/utils/function';

import { atom, onSet, onStart } from 'nanostores';

type StreamValue =
  | { state: 'pending' | 'rejected'; stream?: void }
  | { state: 'resolved'; stream: MediaStream };
export const $videoStream = atom<StreamValue>({ state: 'pending' });

onMountOnce($videoStream, () => {
  let streamRef: MediaStream | null = null;
  const set = (videoInput?: string, audioInput?: string) => {
    if (import.meta.env.VITE_DISABLE_CAMERA) {
      return $videoStream.set({
        state: 'resolved',
        stream: null as unknown as any,
      });
    }

    return navigator.mediaDevices
      .getUserMedia({
        audio: { deviceId: audioInput },
        video: {
          facingMode: 'user',
          deviceId: videoInput,
        },
      })
      .then((stream) => {
        streamRef = stream;
        $videoStream.set({ state: 'resolved', stream });
      })
      .catch(() => $videoStream.set({ state: 'rejected' }));
  };

  const { audioInput, videoInput } = $settings.get();
  set(videoInput, audioInput);
  const stopOnSet = onSet(
    $settings,
    ({ newValue: { videoInput, audioInput } }) => set(videoInput, audioInput),
  );

  return () => {
    stopOnSet();
    streamRef?.getTracks().forEach((track) => track.stop());
  };
});

type DevicesValue = Record<MediaDeviceKind, { label: string; id: string }[]>;
export const $devicesByType = atom<DevicesValue | undefined>();
onStart($devicesByType, () => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => {
      $devicesByType.set(
        devices.reduce((acc, device) => {
          if (!acc[device.kind]) acc[device.kind] = [];
          acc[device.kind].push({ label: device.label, id: device.deviceId });
          return acc;
        }, {} as DevicesValue),
      );
    })
    .catch(noop);
});
