import { FC } from 'react';

import { block, root } from './Loader.css';

type Props = unknown;

export const Loader: FC<Props> = () => {
  return <div className={root} />;
};

export const LoaderBlock: FC = () => {
  return (
    <div className={block}>
      <Loader />
    </div>
  );
};
