import { $currentMap } from '@state/map';

import Phaser from 'phaser';

export class PreloaderScene extends Phaser.Scene {
  constructor() {
    super('Preloader');
  }

  preload() {
    const {
      id,
      mapData: { mapUrl, tiles },
    } = $currentMap.get();

    for (const { name, url } of tiles) {
      this.load.image(`mapTiles:${name}`, url);
    }
    this.load.tilemapTiledJSON(`map:${id}`, mapUrl);
  }

  create() {
    console.log('[transition] preloader transition to tilemap');
    setTimeout(() => {
      this.scene.transition({
        target: 'TileMap',
      });
    }, 100);
  }
}
