import { FC } from 'react';

import { useStore } from '@nanostores/react';

import { i18n } from '@state/i18n';
import { $showSettings } from '@state/misc';
import { LoaderBlock } from '@ui/Loader';
import { Modal } from '@ui/Modal';

import { Settings } from './Settings';

const $messages = i18n('settingsModal', {
  title: 'Settings modal',
});

export const SettingsModal: FC = () => {
  const t = useStore($messages);

  const show = useStore($showSettings);
  if (!show) return null;

  return (
    <Modal title={t.title} onHide={() => $showSettings.set(false)}>
      <Settings pendingSlot={<LoaderBlock />} />
    </Modal>
  );
};
