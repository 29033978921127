import { FC, PropsWithChildren } from 'react';

import { useStore } from '@nanostores/react';

import { Overlay } from '@cmp/Overlay';
import { Portal } from '@cmp/ui/Portal';
import { Presence } from '@cmp/ui/Presence';
import { Spinner } from '@cmp/ui/Spinner';
import { $initActions, $logux } from '@services/logux';
import { i18n } from '@state/i18n';
import { $currentMap, $transitioningToMap } from '@state/map';
import { $event } from '@state/misc';
import { $permissionCheck } from '@state/onboarding';
import { getBestContrastColor } from '@utils/color';
import { useTransientUpdate } from '@utils/hooks/useTransientUpdates';

import { sprinkles } from '@styles/sprinkles.css';

import { atom, computed, onSet } from 'nanostores';

type Props = PropsWithChildren<unknown>;

const $messages = i18n('loading', {
  loading: 'Loading…',
});

const $showApp = computed(
  [$logux, $currentMap, $event, $permissionCheck],
  (client, currentMap, event, onboarding) =>
    client && currentMap && event && onboarding !== 'initial',
);

const $overlayColors = atom<{ bg: string; text: string } | undefined>();
const hideDuration = 0.5;
onSet($currentMap, ({ newValue }) => {
  setTimeout(
    () =>
      $overlayColors.set({
        bg: newValue.mapData.backgroundColor,
        text: getBestContrastColor(newValue.mapData.backgroundColor),
      }),
    hideDuration * 1000 * 2,
  );
});

export const Loading: FC<Props> = ({ children }) => {
  const t = useStore($messages);

  useTransientUpdate($initActions);
  const showApp = useStore($showApp),
    showOverlay = useStore($transitioningToMap);

  const overlayColors = useStore($overlayColors);

  return (
    <>
      <Portal>
        <Presence
          show={showOverlay}
          enter={[{ opacity: 1 }]}
          exit={[{ opacity: 0 }, { duration: hideDuration }]}
        >
          <Overlay
            className={sprinkles({ opacity: 0 })}
            bgColor={overlayColors?.bg}
          >
            <div
              className={sprinkles({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 'lg',
              })}
              style={{ color: overlayColors?.text || '#fff' }}
            >
              <Spinner color={overlayColors?.text} />
              {t.loading}
            </div>
          </Overlay>
        </Presence>
      </Portal>

      {showApp && children}
    </>
  );
};
