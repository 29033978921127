import { browser, createI18n, localeFrom } from '@nanostores/i18n';

export const locale = localeFrom(
  browser({ available: ['en'], fallback: 'en' }),
);

/**
 * This is not used for now.
 * We only have one locale available and no translations, so we only use
 * the base locale.
 * But if we ever need to add anything above it, it's as simple as
 * throwing in a few JSON-files.
 */
const translations = import.meta.globEager('./translations/*.json');
export const i18n = createI18n(locale, {
  async get(code) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return translations[code]!;
  },
});
