import { FC, useEffect } from 'react';

import { ClientContext } from '@logux/client/react';
import { useStore } from '@nanostores/react';
import { setElementVars } from '@vanilla-extract/dynamic';

import { Boundary } from '@cmp/Boundary';
import { HelpModal } from '@cmp/HelpModal';
import { IFrame, TypeformFrame } from '@cmp/IFrame';
import { Loading } from '@cmp/Loading';
import { MainGrid } from '@cmp/MainGrid';
import { Onboarding } from '@cmp/Onboarding';
import { Overlay } from '@cmp/Overlay';
import { SettingsModal } from '@cmp/Settings/SettingsModal';
import { Portal } from '@cmp/ui/Portal';
import { Toasts } from '@cmp/ui/Toasts';
import { $logux, $loguxError } from '@services/logux';
import { $dailyRoomJoiner } from '@state/dailyCalls';
import { i18n } from '@state/i18n';
import { $debugMode } from '@state/misc';
import { $event } from '@state/misc';
import { ChannelErrors } from '@utils/vendorChannelErrors';

import '@styles/reset.css';
import '@styles/globalStyle.css';
import { themeVars } from '@styles/theme.css';

import { useControls } from 'leva';

const $messages = i18n('root', {
  unknownError: 'Something went wrong!',
  secondTab:
    'The app is opened in another window. Please, switch to it to open Connect.',
});

export const App: FC = () => {
  const t = useStore($messages);

  const client = useStore($logux);
  const loguxError = useStore($loguxError);

  return (
    <Boundary
      fallback={
        <Portal>
          <Overlay>{t.unknownError}</Overlay>
        </Portal>
      }
    >
      {loguxError === 'secondTab' ? (
        <Portal>
          <Overlay>
            <p>{t.secondTab}</p>
          </Overlay>
        </Portal>
      ) : (
        <Loading>
          <ClientContext.Provider value={client}>
            <ChannelErrors
              NotFound={() => <p>{t.unknownError}</p>}
              AccessDenied={() => <p>{t.unknownError}</p>}
              Error={() => <p>{t.unknownError}</p>}
            >
              <CanvasApp />
            </ChannelErrors>
          </ClientContext.Provider>
        </Loading>
      )}
    </Boundary>
  );
};

const CanvasApp: FC = () => {
  const { ui } = useStore($event);

  useStore($dailyRoomJoiner);

  useEffect(() => {
    setElementVars(document.body, themeVars, ui);
  }, [ui]);

  return (
    <main>
      <MainGrid />
      <Onboarding />
      <HelpModal />
      <SettingsModal />
      <IFrame />
      <TypeformFrame />
      <Toasts />
      {/* {import.meta.env.DEV && <Debug />} */}
    </main>
  );
};

const Debug = () => {
  useControls({
    debug: {
      value: $debugMode.get(),
      onChange: (v) => $debugMode.set(v),
    },
  });

  return null;
};
