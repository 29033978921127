import { FC } from 'react';

import { count } from '@nanostores/i18n';
import { useStore } from '@nanostores/react';

import { OtherVideos, SelfVideo } from '@cmp/Call';
import { Controls } from '@cmp/Controls';
import { PortalBelowAppParent } from '@cmp/PortalBelowApp';
import { $callParticipantsComputed } from '@state/dailyCalls';
import { i18n } from '@state/i18n';
import { $onboardingFinished } from '@state/onboarding';

import {
  $bottomSheetHeightData,
  attendeesRef,
  onClickHandler,
  oneLineRef,
  rootRef,
} from './state';

import {
  attendees,
  blockButton,
  blockSpan,
  hiddenSheet,
  root,
} from './BottomSheet.css';
import { sprinkles } from '@styles/sprinkles.css';

import clsx from 'clsx';

type Props = unknown;

const $messages = i18n('bottomSheet', {
  attendees: count({
    one: 'Only you',
    many: '{count} attendees',
  }),
});

export const BottomSheet: FC<Props> = () => {
  const attendeeCount = Object.keys(
    useStore(useStore($callParticipantsComputed)),
  ).length;
  const t = useStore($messages);

  const shouldHideSheet = useStore($onboardingFinished) !== 'yep';

  const bottomSheetData = useStore($bottomSheetHeightData);

  return (
    <>
      <PortalBelowAppParent />
      <div
        className={clsx(root, shouldHideSheet && hiddenSheet)}
        ref={rootRef}
        style={bottomSheetData?.style}
      >
        <button className={blockButton} onClick={onClickHandler}>
          <span className={blockSpan} />
        </button>
        <div className={_btnsAndAttendees}>
          <Controls />
          <p className={_text}>{t.attendees(attendeeCount + 1)}</p>
          <div className={attendees} ref={attendeesRef}>
            <div ref={oneLineRef}>
              <SelfVideo />
            </div>
            <OtherVideos />
          </div>
        </div>
      </div>
    </>
  );
};

const _btnsAndAttendees = sprinkles({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 'mdP',
  }),
  _text = sprinkles({ color: 'gray' });
