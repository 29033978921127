import { FC, PropsWithChildren, useEffect, useState } from 'react';

type Props = PropsWithChildren<{
  ms: number;
}>;

export const Delayed: FC<Props> = ({ children, ms }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, ms);
    return () => clearTimeout(timer);
  }, [ms]);

  return isShown ? <>{children}</> : null;
};
