/**
 * Real pos — the one applied to the grid
 * Coords — coords on the canvas
 */
export class Pos {
  constructor(
    public realPos: ITwoPointCoords,
    public tileWidth: number,
    public tileHeight: number,
  ) {}
  get coords(): ITwoPointCoords {
    return [
      this.realPos[0] * this.tileWidth + this.tileWidth / 2,
      this.realPos[1] * this.tileHeight + this.tileHeight / 2,
    ];
  }
  set coords(coords: ITwoPointCoords) {
    this.realPos = [
      Math.round(coords[0] / this.tileWidth),
      Math.round(coords[1] / this.tileHeight),
    ];
  }

  snapAndAssignRealPosFromCoords(coords: ITwoPointCoords) {
    const snappedRealX = Math.round(
        (coords[0] - this.tileWidth / 2) / this.tileWidth,
      ),
      snappedRealY = Math.round(
        (coords[1] - this.tileHeight / 2) / this.tileHeight,
      );

    const areDifferent =
      this.realPos[0] !== snappedRealX || this.realPos[1] !== snappedRealY;

    if (areDifferent) this.realPos = [snappedRealX, snappedRealY];

    return areDifferent;
  }
  copy() {
    return new Pos([...this.realPos], this.tileWidth, this.tileHeight);
  }
}
