import { FC } from 'react';

import { range } from 'shared/utils/array';

type Props = { count: number; current: number };

export const Steps: FC<Props> = ({ count, current }) => {
  return (
    <svg
      width={count * 10}
      height="6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {range(count).map((n) => {
        const isFilled = n <= current,
          cx = n * 10 + 3;

        return (
          <circle
            key={n}
            cx={cx}
            cy="3"
            r="2.5"
            fill={isFilled ? 'white' : 'none'}
            stroke="white"
            strokeWidth="1"
          />
        );
      })}
    </svg>
  );
};
