export function truthyTypeGuard<T>(
  val: T | null | undefined | false,
): asserts val is T {
  if (val === void 0 || val === false || val === null) {
    throw new Error('Cannot be falsy');
  }
}

export const generateReqResLoopHelper = <Req, Res>(type: string) => {
  const resType = `${type}/done`;

  return {
    type,
    actionCreator: (d: Req) => ({ type, ...d }),
    resType,
    responseCreator: (d: Res) => ({ type: resType, ...d }),
  };
};
