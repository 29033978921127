export const sample = <T>(arr: T[]) =>
  arr[Math.floor(Math.random() * arr.length)];

export const range = (num: number) => [...Array(num).keys()];

export const boolMerge = <T, Y>(arr1: T[], arr2: Y[]) =>
  arr1.map((val, index) => Boolean(val && arr2[index]));

export const sameArrays = <T, Y>(arr1: T[], arr2: Y[]) => {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};
