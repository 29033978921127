import {
  cloneElement,
  FC,
  PropsWithChildren,
  ReactNode,
  useState,
} from 'react';

import {
  offset,
  Placement,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';

import { useIsMobile } from '@utils/hooks/useIsMobile';

import { Presence } from '../Presence';

import { _body } from './Tooltip.css';

export const Tooltip: FC<{
  label: ReactNode;
  placement?: Placement;
  children: JSX.Element;
}> = ({ children, label, placement = 'top' }) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    strategy: 'fixed',
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(8)],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
  ]);

  if (useIsMobile()) return children;

  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props }),
      )}
      <Presence
        show={open}
        enter={[{ opacity: 1 }, { duration: 0.2 }]}
        exit={[{ opacity: 0 }, { duration: 0.2 }]}
      >
        <div
          {...getFloatingProps({
            ref: floating,
            className: _body,
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            },
          })}
        >
          {label}
        </div>
      </Presence>
    </>
  );
};

export const TooltipBody: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div className={_body}>{children}</div>
);
