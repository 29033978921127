import { FC } from 'react';

import { assignInlineVars } from '@vanilla-extract/dynamic';

import { colorVar, root } from './Spinner.css';

type Props = { color?: string };

export const Spinner: FC<Props> = ({ color = '#fff' }) => {
  return (
    <div className={root} style={assignInlineVars({ [colorVar]: color })}>
      <div></div>
      <div></div>
    </div>
  );
};
