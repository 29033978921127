import { SVGProps } from 'react';

export const FullscreenOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7501 6.50008L12.9143 6.50008L16.4572 2.95718L15.043 1.54297L11.5001 5.08586V2.25008L9.50007 2.25008L9.50007 7.50008V8.50008H10.5001L15.7501 8.50008V6.50008ZM2.25008 11.5H5.08586L1.54297 15.0429L2.95718 16.4571L6.50008 12.9142V15.75H8.50008V10.5V9.50002H7.50008H2.25008V11.5Z"
      fill="white"
    />
  </svg>
);

export const FullscreenOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 4H12.5858L9.04289 7.54289L10.4571 8.95711L14 5.41421V8.25H16V3V2H15H9.75V4ZM8.25 14H5.41421L8.95711 10.4571L7.54289 9.04289L4 12.5858L4 9.75H2L2 15L2 16L3 16H8.25V14Z"
      fill="white"
    />
  </svg>
);
