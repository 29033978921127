import { colord, extend } from 'colord';
import a11yPlugin from 'colord/plugins/a11y';

extend([a11yPlugin]);

const variants = ['#fff', '#000'];
export const getBestContrastColor = (bgColor: string) => {
  const bg = colord(bgColor);

  const sorted = variants.sort((a, b) => bg.contrast(b) - bg.contrast(a));
  return sorted[0] as string;
};
