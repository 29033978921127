import { FC, useRef, useState } from 'react';
import { useCounter } from 'react-use';

import { params } from '@nanostores/i18n';
import { useStore } from '@nanostores/react';

import { Settings } from '@cmp/Settings';
import { i18n } from '@state/i18n';
import { $currentMap } from '@state/map';
import { $event } from '@state/misc';
import {
  $mapOnboardingFinishedMapIds,
  $onboardingFinished,
  $permissionCheck,
  addFinishedMapId,
  trackOnboardingState,
} from '@state/onboarding';
import { $currentUser } from '@state/user';
import { Button } from '@ui/Button';
import { Content } from '@ui/Content';
import { Delayed } from '@ui/Delayed';
import { Modal, ModalRef } from '@ui/Modal';
import { Steps } from '@ui/Steps';
import { useIsMobile } from '@utils/hooks/useIsMobile';
import { EventApi } from 'shared/types';
import { truthyTypeGuard } from 'shared/utils/ts';

import {
  _col,
  _root,
  buttonStyle,
  firstPart,
  progress,
  stepsHeader,
  stepsRoot,
} from './Onboarding.css';

export const Onboarding: FC = () => {
  const onb = useStore($permissionCheck);

  if (import.meta.env.VITE_DISABLE_CAMERA) return null;

  if (onb === 'ok')
    return (
      <Delayed ms={700}>
        <MainOnboardingSteps />
      </Delayed>
    );

  return <OnboardingSettings />;
};

const $messagesSettings = i18n('onboarding.settings', {
  modalTitle: 'Hello!',
  introduction: params<{ name: string }>('Hi, {name}!'),
  join: 'Join the lobby',
});

const OnboardingSettings: FC = () => {
  const t = useStore($messagesSettings);

  const user = useStore($currentUser),
    event = useStore($event);

  const [buttonState, setButtonState] = useState<
    'disabled' | 'enabled' | 'hidden'
  >('disabled');

  const modalRef = useRef<ModalRef | null>(null);

  return (
    <Modal
      title={t.modalTitle}
      onHide={() => {
        trackOnboardingState('Permissions granted');
        $permissionCheck.set('ok');
      }}
      ref={modalRef}
      nonDismissable
    >
      <div className={_root}>
        <div className={_col}>
          <Content className={firstPart}>
            <h2>{t.introduction({ name: user.name })}</h2>
            <div dangerouslySetInnerHTML={{ __html: event.welcome_message }} />
          </Content>
          <div>
            {buttonState !== 'hidden' && (
              <Button
                disabled={buttonState === 'disabled'}
                onClick={() => modalRef.current?.hide()}
              >
                {event.join_message || t.join}
              </Button>
            )}
          </div>
        </div>
        <div className={_col}>
          <Settings
            setVideoAvailable={() => setButtonState('enabled')}
            setVideoRejected={() => setButtonState('hidden')}
          />
        </div>
      </div>
    </Modal>
  );
};

const $messagesSteps = i18n('onboarding.steps', {
  modalTitle: 'How to use Connect',
  introduction: 'Welcome!',
  controlsMobile: 'Tap anywhere on the map to move.',
  controlsDesktop:
    'Use your mouse, arrows on the keyboard (←↑→↓) or WASD to move.',
  next: 'Next',
  whatToDo: 'Great! What can I do?',
  approach:
    'When you come closer to someone, a video call starts. You can even join group calls — just give it a try!',
  map: `What's on the map?`,
  flags: `Some objects on the map are interactive. Check out what's there!`,
  finish: 'Finish',
});

const MainOnboardingSteps: FC = () => {
  const t = useStore($messagesSteps);

  const isMobile = useIsMobile();
  const defaultSteps = [
    {
      title: t.introduction,
      text: isMobile ? t.controlsMobile : t.controlsDesktop,
    },
    { title: t.whatToDo, text: t.approach },
    { title: t.map, text: t.flags },
  ];

  const onboardingStep = useStore($onboardingFinished);

  const event = useStore($event),
    instructions = event.instructions || defaultSteps;

  if (onboardingStep === 'yep')
    return (
      <Delayed ms={700}>
        <MapOnboardingSteps />
      </Delayed>
    );

  return (
    <ShowSteps
      steps={instructions}
      onFinish={() => $onboardingFinished.set('yep')}
    />
  );
};

const MapOnboardingSteps: FC = () => {
  const map = useStore($currentMap),
    onboardingPassedMapIds = useStore($mapOnboardingFinishedMapIds);

  if (
    !map.mapData.instructions?.length ||
    onboardingPassedMapIds.includes(map.id)
  )
    return null;

  return (
    <ShowSteps
      steps={map.mapData.instructions}
      onFinish={() => addFinishedMapId(map.id)}
    />
  );
};

const ShowSteps: FC<{
  steps: Exclude<EventApi['instructions'], undefined>;
  onFinish: () => void;
}> = ({ steps, onFinish }) => {
  const t = useStore($messagesSteps);

  const modalRef = useRef<ModalRef | null>(null);

  const [stepIndex, { inc }] = useCounter(0),
    isFinalStep = stepIndex >= steps.length - 1;

  const step = steps[stepIndex];
  truthyTypeGuard(step);
  const { title, text } = step;

  return (
    <Modal
      title={t.modalTitle}
      ref={modalRef}
      design="bottom"
      padding="sm"
      onHide={onFinish}
    >
      <div className={stepsRoot}>
        <h2 className={stepsHeader}>{title}</h2>
        <p>{text}</p>
        <div className={progress}>
          <Button
            className={buttonStyle}
            design="ghost"
            onClick={() => (isFinalStep ? modalRef.current?.hide() : inc())}
          >
            {isFinalStep ? t.finish : t.next}
          </Button>
          <Steps count={steps.length} current={stepIndex} />
        </div>
      </div>
    </Modal>
  );
};
