import { SVGProps } from 'react';

export const Menu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.49789 6.61542H18.4979"
      stroke="currentColor"
      strokeWidth="2.30769"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.49789 11.2308H18.4979"
      stroke="currentColor"
      strokeWidth="2.30769"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.49789 15.8462H18.4979"
      stroke="currentColor"
      strokeWidth="2.30769"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const MenuOpened = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.875 15.7757C2.875 16.282 3.28541 16.6924 3.79167 16.6924H14.4167C14.9229 16.6924 15.3333 16.282 15.3333 15.7757C15.3333 15.2695 14.9229 14.8591 14.4167 14.8591H3.79167C3.2854 14.8591 2.875 15.2695 2.875 15.7757ZM2.875 11.1924C2.875 11.6986 3.28541 12.1091 3.79167 12.1091H11.5417C12.0479 12.1091 12.4583 11.6986 12.4583 11.1924C12.4583 10.6861 12.0479 10.2757 11.5417 10.2757H3.79167C3.28541 10.2757 2.875 10.6861 2.875 11.1924ZM3.79167 5.69238C3.2854 5.69238 2.875 6.10279 2.875 6.60905C2.875 7.11531 3.28541 7.52572 3.79167 7.52572H14.4167C14.9229 7.52572 15.3333 7.11531 15.3333 6.60905C15.3333 6.10279 14.9229 5.69238 14.4167 5.69238H3.79167ZM19.4198 15.1577C19.8043 14.79 19.8047 14.176 19.4208 13.8077L17.5623 12.0251C17.0888 11.5709 17.0888 10.8139 17.5623 10.3597L19.4208 8.57704C19.8047 8.20877 19.8043 7.59477 19.4198 7.22704C19.0585 6.88138 18.489 6.88138 18.1277 7.22704L14.8538 10.3586C14.3788 10.813 14.3788 11.5718 14.8538 12.0262L18.1277 15.1577C18.489 15.5034 19.0585 15.5034 19.4198 15.1577Z"
      fill="currentColor"
    />
  </svg>
);
