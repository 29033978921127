import { init, track } from '@amplitude/analytics-browser';

const ampliToken = import.meta.env.VITE_AMPLITUDE_TOKEN;
export const analyticsInit = (userId: string) => {
  ampliToken && init(ampliToken, userId);
};

// Very simple 250 debounce by eventname
const timers: Record<string, number> = {};
export const trackEvent = (
  eventName: string,
  props?: Record<string, unknown>,
) => {
  if (timers[eventName]) clearTimeout(timers[eventName]);

  timers[eventName] = window.setTimeout(() => {
    if (import.meta.env.DEV) {
      console.log('[EVENT]', eventName, props);
    } else if (ampliToken) {
      track(eventName, props);
    }
  }, 250);
};
