import { $currentMap, $transitioningToMap } from '@state/map';

import EasyStar from 'easystarjs';
import { Objects } from 'game/objects/objects';
import { Players } from 'game/objects/players';
import Phaser from 'phaser';

export class TileMapScene extends Phaser.Scene {
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  private players: Players | null = null;

  private prevMapId: string | null = null;

  constructor() {
    super('TileMap');
  }

  preload() {
    this.cursors = this.input.keyboard.createCursorKeys();
  }

  create() {
    const {
      id,
      mapData: { tiles, noExtrusion },
    } = $currentMap.get();
    if (id === this.prevMapId) return;
    console.log('Creating map');

    const tilemap = this.make.tilemap({
        key: `map:${id}`,
      }),
      tilesets = tiles.map(({ name }) =>
        tilemap.addTilesetImage(
          name,
          `mapTiles:${name}`,
          tilemap.tileWidth,
          tilemap.tileHeight,
          noExtrusion ? 0 : 1,
          noExtrusion ? 0 : 2,
        ),
      );

    const map = tilemap.layers.map((layer) =>
      tilemap
        .createLayer(layer.name, tilesets)
        .setCollisionByProperty({ collides: true, collide: true }),
    );

    if (import.meta.env.DEV) {
      const debugGraphics = this.add.graphics().setAlpha(0.3);

      map.forEach((tile) =>
        tile.renderDebug(debugGraphics, {
          tileColor: null,
          collidingTileColor: new Phaser.Display.Color(243, 234, 48, 255),
          faceColor: new Phaser.Display.Color(40, 39, 37, 255),
        }),
      );
    }

    const gameObjects = new Objects(this, tilemap);
    const finder = this.createFinder(tilemap);

    this.players = new Players(
      this,
      map,
      gameObjects,
      tilemap.tileWidth,
      tilemap.tileHeight,
    );
    this.players.initPlayers();
    this.input.on(
      Phaser.Input.Events.POINTER_DOWN,
      ({ worldX, worldY }: Phaser.Input.Pointer) => {
        this.players?.handleClickNav(
          finder,
          tilemap.worldToTileX(worldX),
          tilemap.worldToTileY(worldY),
        );
      },
    );

    $transitioningToMap.set(false);

    this.scene.scene.events.on('transitionout', () => {
      this.players?.destroy();
      this.players = null;
      this.scene.stop();
      gameObjects.destroy();
    });
    this.scene.scene.events.on('transitioncomplete', () => {
      this.create();
    });

    this.prevMapId = id;
  }

  createFinder(tilemap: Phaser.Tilemaps.Tilemap) {
    const finder = new EasyStar.js();

    let joinedTileProperties: Record<
      string,
      { collides?: boolean } | undefined
    > = {};
    for (const tileset of tilemap.tilesets) {
      joinedTileProperties = {
        ...joinedTileProperties,
        ...Object.fromEntries(
          Object.entries(tileset.tileProperties).map(([id, val]) => [
            +id + tileset.firstgid,
            val,
          ]),
        ),
      };
    }

    const undefinedRange = (num: number) => [...Array(num)];
    const grid: number[][] = undefinedRange(tilemap.width).map(() =>
      undefinedRange(tilemap.height),
    );

    /* eslint-disable @typescript-eslint/no-non-null-assertion */

    for (const layer of tilemap.layers) {
      for (let x = 0; x < layer.data.length; x++) {
        const row = layer.data[x]!;
        for (let y = 0; y < row.length; y++) {
          const prevSaved = grid[x]![y]!;
          const tileIndex = row[y]!.index;
          const canGoThere = Number(
            tileIndex === -1
              ? false
              : !(joinedTileProperties[tileIndex]?.collides === true),
          );

          // If it's the first run for this tile, always set the value
          if (prevSaved === undefined) {
            grid[x]![y] = canGoThere;
          }
          // If current tile is not empty and previously saved allows walking on it,
          // always rewrite the value (it can either be 0 or 1, doesn't matter)
          if (prevSaved === 1 && tileIndex !== -1) {
            grid[x]![y] = canGoThere;
          }
        }
      }
    }

    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    finder.setGrid(grid);
    finder.setAcceptableTiles(1);
    finder.enableDiagonals();

    return finder;
  }

  update() {
    if (this.players) this.players.update(this.cursors);
  }
}
